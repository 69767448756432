import { Number } from "core-js";

const state = function() {
  return {
    isResponse: Number,
    getFlateFare:{},
    // userUpdatePassword: {},
    // ListPaymentMethod: [],
    // getPaymentMethod: {},
    // successPayment:{},
    isMsg: String
  };
};

export default state;
