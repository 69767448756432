import { Number } from "core-js";

const state = function() {
  return {
    isResponse: Number,
    photo_identiti_card:"",
    photo_selfie_id:"",
    photo_selfie:"",
    isMsg: String
  };
};

export default state;
