import state from "./moduleMyTicketState.js";
import mutations from "./moduleMyTicketMutations.js";
import actions from "./moduleMyTicketActions.js";
import getters from "./moduleMyTicketGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
