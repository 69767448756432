export default {
    CREATE_SUCCESS(state, val) {
      state.isResponse.code = val;
    },
    CREATE_FAILED(state, val) {
      state.isMsg.message = val;
    },
    
    EDIT_PAYMENT_METHOD_SUCCESS(state, val) {
      state.isResponse.code = val;
    },
    EDIT_PAYMENT_METHOD_FAILED(state, val) {
      state.isMsg.message = val;
    },
  
    SET_PRIMARY_SUCCESS(state, val) {
      state.isResponse.code = val;
    },
    SET_PRIMARY_FAILED(state, val) {
      state.isMsg.message = val;
    },
  
    UPDATE_PASSWORD_SUCCESS(state, val) {
      state.isResponse.code = val;
    },
    GET_PAYMENT_METHOD_SUCCESS(state,val){
      state.ListPaymentMethod = val
    },
  
    GET_OBJECT_PAYMENT_METHOD_SUCCESS(state,val){
      state.getPaymentMethod = val
    },
  
    GET_OBJECT_PAYMENT_METHOD_FAILED(state,val){
      state.isMsg = val
    },
  
    DELETE_PAYMENT_METHOD_SUCCESS(state, val){
      state.successPayment = val;
    },
    DELETE_PAYMENT_METHOD_FAILED(state, val){
      state.isMsg = val;
    },
    GET_CHECK_KYC(state,val){
      state.checkKyc = val;
    }
  };
  