import { render, staticRenderFns } from "./HeaderBreadcrumb.vue?vue&type=template&id=23d439a0&scoped=true&"
import script from "./HeaderBreadcrumb.vue?vue&type=script&lang=js&"
export * from "./HeaderBreadcrumb.vue?vue&type=script&lang=js&"
import style0 from "./HeaderBreadcrumb.vue?vue&type=style&index=0&id=23d439a0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23d439a0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAppBar,VIcon,VSpacer,VToolbarTitle})
