import APIMenuManagement from "@/utils/apiMenuManagement";
import { url } from '@/configs/apiUrl'
export default {
    
    getPrivacyPolicy: () => {
        const getPrivacy = new Promise((resolve, reject) => {
            APIMenuManagement.get(url.wsc.menu_management.static_content_wsc_by_id_1).then(response => {
                if (response.data.code == 200) {
                    resolve(response.data.data);
                } else {
                    resolve({ status: false });
                }
            }).catch(e => {
                reject(e);
            });
        });
        return getPrivacy;
    },

    getTOS: () => {
        const getTOSData = new Promise((resolve, reject) => {
            APIMenuManagement.get(url.wsc.menu_management.static_content_wsc_by_id_2).then(response => {
                if (response.data.code == 200) {
                    resolve(response.data.data);
                } else {
                    resolve({ status: false });
                }
            }).catch(e => {
                reject(e);
            });
        });
        return getTOSData;
    },

    getHelpCenter: () => {
        const getHelpCenter = new Promise((resolve, reject) => {
            APIMenuManagement.get(url.wsc.menu_management.get_helper_center).then(response => {
                if (response.data.code == 200) {
                    resolve(response.data.data);
                } else {
                    resolve({ status: false });
                }
            }).catch(e => {
                reject(e);
            });
        });
        return getHelpCenter;
    }
    
}