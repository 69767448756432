import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Cookies from "js-cookie";
import i18n from "../i18n/i18n";

Vue.use(VueRouter);

let routes = [
  {
    path: "/otpvalidate",
    name: "OTPValidate",
    component: () => import("@/views/auth/ValidationUser.vue"),
    props: {
      header: true,
      content: true,
      breadcrumb: [
        { title: "OTP", url: "/otpvalidate" },
        { title: "OTP", active: true },
      ],
    },
  },
  {
    path: "/",
    name: "Homepage",
    component: () => import("@/views/auth/Homepage.vue"),
    meta: {
      // redirectIfAuthenticated: true,
      breadcrumb: [
        { title: "Homepage", url: "/" },
        { title: "Homepage", active: true },
      ],
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      redirectIfAuthenticated: true,
      breadcrumb: [
        { title: "Login", url: "/login" },
        { title: "Login", active: true },
      ],
    },
  },
  {
    path: "/validation",
    name: "ValidationUser",
    component: () => import("@/views/auth/ValidationUser.vue"),
    meta: {
      redirectIfAuthenticated: true,
      breadcrumb: [
        { title: "ValidationUser", url: "/validation" },
        { title: "ValidationUser", active: true },
      ],
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/auth/Register.vue"),
    meta: {
      redirectIfAuthenticated: true,
      breadcrumb: [
        { title: "Register", url: "/register" },
        { title: "Register", active: true },
      ],
    },
  },
  {
    path: "/login-v2",
    name: "LoginV2",
    component: () => import("@/views/auth/Login-v2.vue"),
    meta: {
      redirectIfAuthenticated: true,
      breadcrumb: [
        { title: "Login", url: "/login" },
        { title: "Login", active: true },
      ],
    },
  },
  {
    path: "/register-v2",
    name: "RegisterV2",
    component: () => import("@/views/auth/Register-v2.vue"),
    meta: {
      redirectIfAuthenticated: true,
      breadcrumb: [
        { title: "Register", url: "/register" },
        { title: "Register", active: true },
      ],
    },
  },
  // {
  //   path: "/home",
  //   name: "Home",
  //   component: () => import("@/views/module/Home.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/home" },
  //       { title: "Home", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/about",
  //   name: "About",
  //   component: () => import("@/views/module/About.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "Profile", active: true },
  //     ],
  //   },
  // },`
  // {
  //   path: "/premiummember",
  //   name: "PremiumMember",
  //   component: () => import("@/views/membership/Index.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "UpgradeMembership", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/ticket",
  //   name: "Ticket",
  //   component: () => import("@/views/purchaseTicket/PurchaseTicket.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "PurchaseTicket", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/myticket",
  //   name: "MyTicket",
  //   component: () => import("@/views/myTicket/myticket.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "BuyTicket", url: "/" },
  //       { title: "MyTicket", active: true },
  //     ],
  //   },
  // },
  {
    path: "/myticketGobus",
    name: "MyTicketGobus",
    component: () => import("@/views/myTicket/viewticketGobus.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { title: "BuyTicket", url: "/" },
        { title: "MyTicket", active: true },
      ],
    },
  },
  // {
  //   path: "/viewticket",
  //   name: "ViewTicket",
  //   component: () => import("@/views/myTicket/viewticket.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "BuyTicket", url: "/" },
  //       { title: "DetailTicket", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/flatefare",
  //   name: "FlateFare",
  //   component: () => import("@/views/purchaseTicket/flatfare/FlateFare.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     notAvailableInGobus: true,
  //     breadcrumb: [
  //       { title: "BuyTicket", url: "/ticket" },
  //       { title: "SingleTrip", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/multitrips",
  //   name: "MultiTrips",
  //   component: () => import("@/views/purchaseTicket/MultiTrips.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     // requirePremium: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/ticket" },
  //       { title: "Evoucher", active: true },
  //     ],
  //   },
  // },
  {
    path: "/studentfare",
    name: "StudentFare",
    component: () => import("@/views/purchaseTicket/StudentFare.vue"),
    meta: {
      requiresAuth: true,
      requireStudent: true,
      notAvailableInGobus: true,
      breadcrumb: [
        { title: "Dashboard", url: "/ticket" },
        { title: "StudentFare", active: true },
      ],
    },
  },
  // {
  //   path: "/cart",
  //   name: "Cart",
  //   component: () => import("@/views/purchaseTicket/Cart.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "Cart", active: true },
  //     ],
  //   },
  // },
  {
    path: "/flate-cart",
    name: "FlateCart",
    component: () => import("@/views/purchaseTicket/FlateCart.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { title: "Dashboard", url: "/" },
        { title: "FlateCart", active: true },
      ],
    },
  },
  // {
  //   path: "/checkout",
  //   name: "Checkout",
  //   component: () => import("@/views/purchaseTicket/Checkout.vue"),
  //   props: {
  //     header: true,
  //     content: true,
  //   },
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "Checkout", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/transactionhistory",
  //   name: "TransactionHistory",
  //   component: () =>
  //     import(
  //       "@/views/purchaseTicket/transactionHistory/TransactionHistory.vue"
  //     ),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "TransactionHistory", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/detailhistory",
  //   name: "DetailHistory",
  //   component: () =>
  //     import(
  //       "@/views/purchaseTicket/transactionHistory/TransactionHistoryDetail.vue"
  //     ),
  //   props: true,
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "DetailHistory", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/result-ticket",
  //   name: "ResultTicket",
  //   component: () => import("@/views/purchaseTicket/ResultTicket.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "Result", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/paymentmethod/list",
  //   name: "PaymentMethodList",
  //   component: () => import("@/views/paymentMethod/List.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "MyWallet", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/paymentmethod/add",
  //   name: "PaymentMethodAdd",
  //   component: () => import("@/views/paymentMethod/Add.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "AddWallet", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/paymentmethod/detail",
  //   name: "PaymentMethodDetail",
  //   component: () => import("@/views/paymentMethod/Detail.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "SettingsWallet", active: true },
  //     ],
  //   },
  // },

  {
    path: "/paymentmethod/showpreview",
    name: "ShowPreview",
    component: () => import("@/views/paymentMethod/ShowPreview.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { title: "Dashboard", url: "/" },
        { title: "SettingsPayment", active: true },
      ],
    },
  },
  {
    path: "/parent/parentalcccess",
    name: "ParentalAccess",
    component: () => import("@/views/parent/ParentalAccess.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { title: "Dashboard", url: "/" },
        { title: "ConfirmStudent", active: true },
      ],
    },
  },
  {
    path: "/parent/studentdetail",
    name: "DetailStudent",
    component: () => import("@/views/parent/DetailStudent.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { title: "Dashboard", url: "/" },
        { title: "StudentDetail", active: true },
      ],
    },
  },
  {
    path: "/promotionprogram",
    name: "PromotionProgram",
    component: () => import("@/views/promotionprogram/PromotionProgram.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { title: "Dashboard", url: "/" },
        { title: "PromotionProgram", active: true },
      ],
    },
  },
  // {
  //   path: "/studentmembership",
  //   name: "StudentMembership",
  //   component: () =>
  //     import("@/views/profile/studentMembership/StudentMembership.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "StudentMembership", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/premiummembership",
  //   name: "PremiumMembership",
  //   component: () =>
  //     import("@/views/profile/premiumMembership/PremiumMembership.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "PremiumMembership", active: true },
  //     ],
  //   },
  // },
  {
    path: "/detailpromo",
    name: "DetailPromotion",
    component: () => import("@/views/promotionprogram/DetailPromotion.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { title: "Dashboard", url: "/" },
        { title: "DetailPromotion", active: true },
      ],
    },
  },
  // {
  //   path: "/kyc/onidentitycardkyc",
  //   name: "CameraOnIdentityCardKyc",
  //   component: () => import("@/views/studentkyc/CameraOnIdentityCardKyc.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "IdentityCard", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/kyc/selfieidentitycard",
  //   name: "SelfieIdentityCard",
  //   component: () => import("@/views/studentkyc/SelfieIdentityCard.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "SelfieIdentityCard", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/kyc/onselfieidentitycard",
  //   name: "CameraOnSelfieIdentityCard",
  //   component: () =>
  //     import("@/views/studentkyc/CameraOnSelfieIdentityCard.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "OnCamSelfieIdentityCard", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/kyc/onselfie",
  //   name: "CameraOnSelfie",
  //   component: () => import("@/views/studentkyc/CameraOnSelfie.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "OnCamSelfie", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/completedatastart",
  //   name: "CompleteDataStart",
  //   component: () => import("@/views/studentkyc/CompleteDataStart.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "CompleteData", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/changepassword",
  //   name: "ChangePassword",
  //   component: () => import("@/views/profile/ChangePassword.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     notAvailableInGobus: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "titleChangePassword", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/editprofile",
  //   name: "EditProfile",
  //   component: () => import("@/views/profile/EditProfile.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "EditProfile", active: true },
  //     ],
  //   },
  // },
  {
    path: "/editprofilegobus",
    name: "EditProfileGobus",
    component: () => import("@/views/profile/EditProfileGobus.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { title: "Dashboard", url: "/" },
        { title: "EditProfile", active: true },
      ],
    },
  },
  {
    path: "/registerupdate",
    name: "RegisterUpdate",
    component: () => import("@/views/auth/RegisterUpdate.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { title: "Dashboard", url: "/" },
        { title: "RegisterUpdate", active: true },
      ],
    },
  },
  {
    path: "/closeApp",
    name: "CloseApp",
    component: () => import("@/views/auth/CloseApp.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { title: "Dashboard", url: "/" },
        { title: "CloseApp", active: true },
      ],
    },
  },
  // {
  //   path: "/privacypolicy",
  //   name: "PrivacyPolicy",
  //   component: () => import("@/views/profile/PrivacyPolicy.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "PrivacyPolicy", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/termofservice",
  //   name: "TermOfService",
  //   component: () => import("@/views/profile/TermOfService.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "TermOfService", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/helpcenter",
  //   name: "HelpCenter",
  //   component: () => import("@/views/profile/HelpCenter.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "HelpCenter", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/student/req",
  //   name: "ReqStudent",
  //   component: () => import("@/views/student/ReqStudent.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "RequestStudent", active: true },
  //     ],
  //   },
  // },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: () => import("@/views/profile/ForgotPassword.vue"),
    meta: {
      redirectIfAuthenticated: true,
      breadcrumb: [
        { title: "Dashboard", url: "/" },
        { title: "ResetPassword", active: true },
      ],
    },
  },
  {
    path: "/resetpass/:sc/:id",
    name: "ResetPassword",
    component: () => import("@/views/profile/ResetPass.vue"),
    props: true,
    meta: {
      redirectIfAuthenticated: true,
      breadcrumb: [
        { title: "Dashboard", url: "/" },
        { title: "titleChangePassword", active: true },
      ],
    },
  },
  //Gobus Only
  {
    path: "/gobus/myticket/:refreshtoken/:busqr",
    name: "gobusmyticket",
    component: () => import("@/views/myTicketGobus/myticket.vue"),
    props: true,
    meta: {
      // isAuthenticated: false,
      breadcrumb: [
        { title: "Dashboard", url: "/" },
        { title: "MyTicket", active: true },
      ],
    },
  },
  // {
  //   path: "/scanqr",
  //   name: "ScanQR",
  //   component: () => import("@/views/mpmValidate/ScanQR.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "ScanQRCodetoGetATicket", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/mytrip",
  //   name: "MyTrip",
  //   component: () => import("@/views/myTrip/mytrip.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "MyTrips", active: true },
  //     ],
  //   },
  // },
  // Hidden Setting Language
  // {
  //   path: "/languange",
  //   name: "SettingLanguange",
  //   component: () => import("@/views/languange/SettingLanguange.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     notAvailableInGobus: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "Language", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/mytrip/detail",
  //   name: "TripDetail",
  //   component: () => import("@/views/myTrip/detailTrip.vue"),
  //   props: true,
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Trip", url: "/mytrip" },
  //       { title: "Details", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/mytrip/detailmultidays",
  //   name: "TripDetailMultidays",
  //   component: () => import("@/views/myTrip/detailTripMultidays.vue"),
  //   props: true,
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Trip", url: "/mytrip" },
  //       { title: "Detail", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/premium/req",
  //   name: "PremiumReq",
  //   component: () => import("@/views/premiumkyc/CompleteDataStart.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "PremiumMember", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/premium/kyc",
  //   name: "IdentityCard",
  //   component: () => import("@/views/premiumkyc/CaptureIdentityCardKyc.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/premium/kyc/selfieidentitycard",
  //   name: "SelfieIdentityCardPremium",
  //   component: () => import("@/views/premiumkyc/SelfieIdentityCard.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "PremiumMember", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/premium/kyc/onselfieidentitycard",
  //   name: "CameraOnSelfieIdentityCardPremium",
  //   component: () => import("@/views/premiumkyc/CameraOnSelfieIdentityCard.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "PremiumMember", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/premium/kyc/onselfie",
  //   name: "CameraOnSelfiePremium",
  //   component: () => import("@/views/premiumkyc/CameraOnSelfie.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "", active: true },
  //     ],
  //   },
  // },
  // {
  //   path: "/qrcode",
  //   name: "QrCode",
  //   component: () => import("@/views/profile/QrCode.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Dashboard", url: "/" },
  //       { title: "QrCode", active: true },
  //     ],
  //   },
  // },
  {
    path: "/gobus/viewticket",
    name: "GobusDetailTicket",
    component: () => import("@/views/myTicketGobus/detailTicket.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { title: "BuyTicket", url: "/" },
        { title: "DetailTicket", active: true },
      ],
    },
  },
  {
    path: "/scanqrbus",
    name: "ScanQRBus",
    component: () => import("@/views/mpmValidate/ScanQRBus.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { title: "Dashboard", url: "/" },
        { title: "ScanQRCodetoGetATicket", active: true },
      ],
    },
  },
  // {
  //   path: "/mytrip/detail",
  //   name: "MyTripInHome",
  //   component: () => import("@/views/myTrip/myTripInHome.vue"),
  //   props: true,
  //   meta: {
  //     requiresAuth: true,
  //     breadcrumb: [
  //       { title: "Trip", url: "/mytrip" },
  //       { title: "Detail", active: true },
  //     ],
  //   },
  // },
  {
    path: "/payment/choose-payment/:payment_token",
    name: "PaymentIndex",
    component: () => import("@/views/payment-page/Index.vue"),
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { title: "Payment", url: "/payment" },
        { title: "Payment Channel", active: true },
      ],
    },
  },
  {
    path: "/payment/dopayment/:payment_token",
    name: "DoPayment",
    component: () => import("@/views/payment-page/do-payment/Index.vue"),
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { title: "Payment", url: "/payment" },
        { title: "Payment", active: true },
      ],
    },
  },
  {
    path: "/payment/status/:payment_token",
    name: "PaymentStatus",
    component: () => import("@/views/payment-page/PaymentStatus.vue"),
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { title: "Payment", url: "/payment" },
        { title: "PaymentStatus", active: true },
      ],
    },
  },
  {
    path: "/payment/cancel",
    name: "PaymentCancel",
    component: () => import("@/views/payment-page/PaymentCancel.vue"),
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { title: "Payment", url: "/payment" },
        { title: "PaymentCancel", active: true },
      ],
    },
  },
];

const handling_redirect = async (path, initialPath, routeTo, next) => {
  try {
    if (JSON.parse(initialPath)[path] == '') {
      const partner_id = localStorage.getItem("partner_id");
      if (partner_id != undefined) {
        await store.dispatch("auth/getPartnerACL", { partner_id: partner_id })
          .then(res => {
            const data = res.data
            return next({ path: data.initial_path[path], query: { ...routeTo.query } });
          })
      }
    } else {
      return next({ path: JSON.parse(initialPath)[path], query: { ...routeTo.query } });
    }
  } catch (error) {
    console.log(error)
  }
}

// add route for dynamic routes
const menuList = JSON.parse(localStorage.getItem('menu_user')) || [];
for (let menu of menuList) {
  var metaData = JSON.parse(menu.menu_meta)
  metaData.component_rules = {
    button_list: menu.button_list || [],
    sub_menu_list: menu.sub_menu_list || []
  }
  var routeData =
  {
    path: menu.menu_path,
    name: menu.menu_name,
    component: () => import('@/views' + menu.menu_component + '.vue'),
    meta: metaData,
  }
  routes.push(routeData)
}

const router = new VueRouter({
  routes,
});

router.beforeEach(async (routeTo, routeFrom, next) => {
  // This case only for logout
  if (routeFrom.name == null && routeTo.name == null && routeFrom.path == "/" && routeTo.path == "/about" && store.state.auth.isAuthenticated == false) {
    return next({ path: store.state.auth.initialPath.login })
  }
  if (!routeTo.matched.length) {
    next('/')
  }
  if (store.state.auth.isAuthenticated == false) {
    if (routeTo.path == '/login' && (routeTo.query.partner_id == undefined || routeTo.query.partner_id == "") && localStorage.getItem("InitialPath") != null) {
      localStorage.removeItem("InitialPath");
      localStorage.removeItem("PartnerACLConfiguration");
      localStorage.removeItem("partner_id");
      await store.dispatch("auth/resetInitialPath", { login: "/login", register: "/register" })
      return next({ path: '/login', query: { ...routeTo.query } });
    }
  }

  if (routeTo.query.partner_id != undefined) {
    const currentPartnerID = localStorage.getItem("partner_id");
    const currentPartnerACL = localStorage.getItem("PartnerACLConfiguration")
    if (currentPartnerID != routeTo.query.partner_id || currentPartnerACL == undefined) {
      localStorage.setItem("partner_id", routeTo.query.partner_id);
      await store.dispatch("auth/getPartnerACL", { partner_id: routeTo.query.partner_id })
        .then(res => {
          const data = res.data
          if (routeTo.query.lang != undefined) {
            localStorage.setItem('language', routeTo.query.language)
            i18n.locale = routeTo.query.language;
          } else {
            localStorage.setItem('language', JSON.parse(localStorage.getItem("PartnerACLConfiguration")).default_language)
            i18n.locale = JSON.parse(localStorage.getItem("PartnerACLConfiguration")).default_language;
          }
          if (res.status) {
            if (routeTo.path == '/login') {
              return next({ path: data.initial_path.login, query: { ...routeTo.query } });
            } else if (routeTo.path == '/register') {
              return next({ path: data.initial_path.register, query: { ...routeTo.query } });
            }
          }
        })
    }
  }

  const initialPath = localStorage.getItem("InitialPath");
  if (initialPath != undefined) {
    // // partner in query param and localStorage different
    // if ((routeTo.path == '/login' || routeTo.path == '/login-v2') && (routeTo.query.partner_id != localStorage.getItem("partner_id"))) {
    //   handling_redirect('login', initialPath, routeTo, next)
    // }
    if ((routeTo.path == '/login' || routeTo.path == '/login-v2') && routeTo.path != JSON.parse(initialPath).login) {
      handling_redirect('login', initialPath, routeTo, next)
    }
    if ((routeTo.path == '/register' || routeTo.path == '/register-v2') && routeTo.path != JSON.parse(initialPath).register) {
      handling_redirect('register', initialPath, routeTo, next)
    }
  } else {
    if ((routeTo.path == '/login-v2')) {
      return next({ path: '/login', query: { ...routeTo.query } });
    }
    if ((routeTo.path == '/register-v2')) {
      return next({ path: '/register', query: { ...routeTo.query } });
    }
  }

  // alert(routeTo.fullPath)
  // let loggedIn = localStorage.getItem('loggedIn');
  // let isAuthenticated = store.state.auth.isAuthenticated;
  if (routeTo.query.lang != undefined) {
    i18n.locale = routeTo.query.lang;
    localStorage.setItem("language", routeTo.query.lang);
  }

  if (routeTo.name == "Home" && routeTo.query.token != undefined) {
    await store.dispatch("auth/logout").then(() => {
      store
        .dispatch("auth/refreshTokenBP", { rToken: routeTo.query.token })
        .then(() => {
          let payment_token =
            routeTo.query.payment_token == undefined
              ? ""
              : "&payment_token=" + routeTo.query.payment_token;
          let tokentrx =
            routeTo.query.tokentrx == undefined
              ? ""
              : "&tokentrx=" + routeTo.query.tokentrx;
          return next(
            "/home?orderId=" +
            routeTo.query.orderId +
            payment_token +
            tokentrx
          );
        });
    });
  } else {
    let isAuthenticated = Cookies.get("loggedIn");
    let access_token = Cookies.get("access_token");
    let refresh_token = Cookies.get("refresh_token");
    let isStudent = store.get("auth/isAllowedStudent");
    let isPremium = store.get("auth/isPremium");
    let isGobus = store.get("auth/provider") == "GOBUS";
    const requiresAuth = routeTo.matched.some((route) => route.meta.requiresAuth);
    const requiresPremium = routeTo.matched.some(
      (route) => route.meta.requirePremium
    );

    const requiresStudent = routeTo.matched.some(
      (route) => route.meta.requireStudent
    );
    const redirectIfAuthenticated = routeTo.matched.some(
      (route) => route.meta.redirectIfAuthenticated
    );
    const notAvailableInGobus = routeTo.matched.some((route) => route.meta.notAvailableInGobus);

    if (routeTo.meta.errorState) return next();
    if (routeTo.meta.isAuthenticated == false) return next();

    if (routeTo.name == "Homepage" || routeTo.name == "gobusmyticket") return next();
    if (isAuthenticated && !requiresAuth) return next("/home");
    if (isAuthenticated && !isStudent && requiresStudent) return next("/home");
    if (isAuthenticated && !isPremium && requiresPremium) return next("/home");
    if (isAuthenticated && isGobus && notAvailableInGobus) return next("/home");

    if (!requiresAuth && !redirectIfAuthenticated) return next();

    if (isAuthenticated && redirectIfAuthenticated) {
      return next("/home");
    }

    if (requiresAuth && !isAuthenticated) {
      return next("/");
    }

    if ((routeFrom.name == "Ticket" ||
      routeFrom.name == "MyTicket" ||
      routeFrom.name == "MyTrip" ||
      routeFrom.name == "TransactionHistory" ||
      routeFrom.name == "About" ||
      routeFrom.name == "MyTripInHome") && (routeTo.name == "Home" || routeTo.name == "Homepage")) {
      return next("/")
    }

    const storeAuth = store.get("auth/getIsAuthentication");
    if (!storeAuth) {
      return next();
    } else if (refresh_token != undefined, access_token != undefined, isAuthenticated == 'true') {
      return next();
    } else {
      store.commit("auth/RESET_ALL_DATA")
      return next('/');
    }
  }
});

export default router;
