import state from "./moduleCPMValidateState";
import mutations from "./moduleCPMValidateMutations";
import actions from "./moduleCPMValidateActions";
import getters from "./moduleCPMValidateGetters";

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
};
