import vue from '../main'


export const partnerACL = {
    getMetaRoute: () => {
        return vue.$route;
    },
    getACLConfiguration: () => {
        return JSON.parse(localStorage.getItem("PartnerACLConfiguration")) || null;
    },
    getBackgroundColorApp: () => {
        return JSON.parse(localStorage.getItem("PartnerACLConfiguration")) ? JSON.parse(localStorage.getItem("PartnerACLConfiguration")).background_color : '#4bb14e';
    },
    getTitleColor: () => {
        return JSON.parse(localStorage.getItem("PartnerACLConfiguration")) ? JSON.parse(localStorage.getItem("PartnerACLConfiguration")).title_color : '#ffffff';
    },
    getButtonColor: () => {
        return JSON.parse(localStorage.getItem("PartnerACLConfiguration")) ? JSON.parse(localStorage.getItem("PartnerACLConfiguration")).button_color : '#4bb14e';
    },
    getSubMenuList: () => {
        return vue.$route.meta.component_rules ? vue.$route.meta.component_rules.sub_menu_list.filter(sub_menu => sub_menu.is_active) : [];
    },
    findSubMenu: (menuName) => {
        return vue.$route.meta.component_rules ? vue.$route.meta.component_rules.sub_menu_list.find(sub_menu => sub_menu.sub_menu_name === menuName) : null;
    },
    findMenu: (menuName) => {
        return JSON.parse(localStorage.getItem("menu_user")) ? JSON.parse(localStorage.getItem("menu_user")).find(menu => menu.menu_name === menuName) : null;
    },
    getSubMenu: () => {
        return vue.$route.meta.component_rules
            ? vue.$route.meta.component_rules.sub_menu_list.filter(sub_menu => sub_menu.is_active)
                .map((smenu) => {
                    return {
                        name: smenu.sub_menu_name,
                        title: JSON.parse(smenu.sub_menu_meta).pageTitle,
                        icon: smenu.sub_menu_icon != "" ? require(`@/assets/icon/${smenu.sub_menu_icon}.svg`) : "",
                        path: smenu.sub_menu_path
                    }
                })
            : [];
    },
    getButtonList: () => {
        return vue.$route.meta.component_rules ? vue.$route.meta.component_rules.button_list.filter(btn => btn.is_active) : [];
    },
    findActiveButton: (buttonList, buttonName) => {
        var button = buttonList.find(btn => btn.button_name == buttonName)
        if (button) {
            button = {
                ...button,
                menu_type: button.menu_id_redirect_group,
                redirect_path: button.menu_id_redirect_path,
                redirect_name: button.menu_id_redirect_name
            }
            return button;
        } else {
            return null;
        }
    },
    getMetaButton: (buttonName) => {
        if (vue.$route.meta.component_rules.button_list) {
            var button = vue.$route.meta.component_rules.button_list.find(btn => btn.button_name == buttonName)
            button = {
                ...button,
                menu_type: button.menu_id_redirect_group,
                redirect_path: button.menu_id_redirect_path,
                redirect_name: button.menu_id_redirect_name
            }
            return button;
        } else {
            return null;
        }
    },
    isShowButton: (buttonName) => {
        if (vue.$route.meta.component_rules.button_list) {
            return vue.$route.meta.component_rules.button_list.find(btn => btn.button_name == buttonName) ? vue.$route.meta.component_rules.button_list.find(btn => btn.button_name == buttonName).is_active : false;
        } else {
            return false;
        }
    },
}