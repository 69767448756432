import { Number } from "core-js";

const state = function() {
  return {
    isResponse: Number,
    getListDiscount: [],
    getListDiscountCode: [],
    isMsg: String
  };
};

export default state;
