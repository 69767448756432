import APIPassenger from "@/utils/apiPassenger";
// import store from "@/store/index";
import { url } from '@/configs/apiUrl'

export default {
  create: ({ commit }, payload) => {

    const createPaymentMethod = new Promise((resolve, reject) => {
      APIPassenger.post(url.wsc.passenger.create_payment_method, payload)
        .then(response => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.data);

            resolve({ status: true });
          } else {
            commit("CREATE_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return createPaymentMethod;
  },

  register: ({ commit }, payload) => {
    const registerPassenger = new Promise((resolve, reject) => {
      APIPassenger.post(url.wsc.passenger.register, payload)
        .then(response => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.data);

            resolve({ status: true });
          } else {
            commit("CREATE_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return registerPassenger;
  },
  registerKyc: ({ commit }, payload) => {

    
    const registerPassenger = new Promise((resolve, reject) => {
      APIPassenger.post(url.wsc.passenger.passenger_kyc_create, payload)
        .then(response => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.data);

            resolve({ status: true });
          } else {
            commit("CREATE_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return registerPassenger;
  },

  premiumKyc: ({ commit }, payload) => {
    const registerPremiumPassenger = new Promise((resolve, reject) => {
      APIPassenger.post(url.wsc.passenger.create_passenger_premium, payload)
        .then(response => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.data);

            resolve({ status: true });
          } else {
            commit("CREATE_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return registerPremiumPassenger;
  },

  selfieIdCardKyc: ({ commit }, payload) => {

    
    const registerselfieIdCardKyc = new Promise((resolve, reject) => {
      APIPassenger.put(url.wsc.passenger.selfie_id_cam, payload)
        .then(response => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.data);

            resolve({ status: true });
          } else {
            commit("CREATE_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return registerselfieIdCardKyc;
  },

  selfieKyc: ({ commit }, payload) => {

    
    const registerselfieCardKyc = new Promise((resolve, reject) => {
      APIPassenger.put(url.wsc.passenger.selfie_cam, payload)
        .then(response => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.data);

            resolve({ status: true });
          } else {
            commit("CREATE_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return registerselfieCardKyc;
  },

  sofPaymentDelete: ({ commit }, payload) => {
    

    const deletePaymentMethod = new Promise((resolve, reject) => {
      APIPassenger.put(url.wsc.passenger.delete_payment_method, payload)
        .then(response => {
          if (response.data.code == 200) {
            commit("DELETE_PAYMENT_METHOD_SUCCESS", response.data.code);

            resolve({ status: true });
          } else {
            commit("DELETE_PAYMENT_METHOD_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return deletePaymentMethod;
  },

  changePassword: ({ commit }, payload) => {
    

    const updatePassword = new Promise((resolve, reject) => {
      APIPassenger.put(url.wsc.passenger.change_password_passenger, payload)
        .then(response => {
          if (response.data.code === 200) {
            commit("UPDATE_PASSWORD_SUCCESS", response.data.code);

            resolve({ status: true });
          } else {
            commit("UPDATE_PASSWORD_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return updatePassword;
  },
  resetPass:({commit}, payload)=>{
      const reset = new Promise((resolve, reject)=>{
        APIPassenger.post(url.wsc.passenger.reset_password, payload)
        .then(response => {
        if (response.data.code === 200) {
          commit("UPDATE_PASSWORD_SUCCESS", response.data.code);

          resolve({ status: true });
        } else {
          commit("UPDATE_PASSWORD_FAILED", response.data.message);

          resolve({ status: false });
        }})
        .catch(e => {
          reject(e);
        });
      })
      return reset;
  },
  editPaymentMethod: ({ commit }, payload) => {
    

    const editPaymentMethod = new Promise((resolve, reject) => {
      APIPassenger.put(url.wsc.passenger.edit_sof_edit, payload)
        .then(response => {
          if (response.data.code === 200) {
            commit("EDIT_PAYMENT_METHOD_SUCCESS", response.data.code);

            resolve({ status: true });
          } else {
            commit("EDIT_PAYMENT_METHOD_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return editPaymentMethod;
  },

  setPrimary: ({ commit }, payload) => {
    

    const setPrimary = new Promise((resolve, reject) => {
      APIPassenger.post(url.wsc.passenger.set_primary, payload)
        .then(response => {
          if (response.data.code === 200) {
            commit("SET_PRIMARY_SUCCESS", response.data.code);

            resolve({ status: true });
          } else {
            commit("SET_PRIMARY_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return setPrimary;
  },

  createPromotionProgram: ({ commit }, payload) => {
    

    const setPrimary = new Promise((resolve, reject) => {
      APIPassenger.post(url.wsc.passenger.promo_create, payload)
        .then(response => {
          if (response.data.code === 200) {
            commit("SET_PRIMARY_SUCCESS", response.data.code);

            resolve({ status: true });
          } else {
            commit("SET_PRIMARY_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return setPrimary;
  },
  
  getPromotionUsed: () => {
    

    const getPromo = new Promise((resolve, reject) => {
      APIPassenger.get(url.wsc.passenger.get_promo_used)
        .then(response => {
          if (response.data.code == 200) {

            resolve(response.data.data);
          } else {
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return getPromo;
  },

  getPromoAvailable: () => {
    

    const getTypePromo = new Promise((resolve, reject) => {
      APIPassenger.get(url.wsc.passenger.get_promo_available)
        .then(response => {
          if (response.data.code == 200) {

            resolve(response.data.data);
          } else {
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return getTypePromo;
  },
  
  editProfile: ({ commit }, payload) => {
    
    console.log(payload)
    const updatePassword = new Promise((resolve, reject) => {
      APIPassenger.put(url.wsc.passenger.edit_profile, payload)
        .then(response => {
          if (response.data.code === 200) {
            commit("UPDATE_PASSWORD_SUCCESS", response.data.code);

            resolve({ status: true });
          } else {
            commit("UPDATE_PASSWORD_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return updatePassword;
  },
  
  registerEditProfilGobus: ({ commit }, payload) => {
    
    // console.log(payload)
    const updatePassword = new Promise((resolve, reject) => {
      APIPassenger.put(url.wsc.passenger.register_edit_profile_gobus, payload)
        .then(response => {
          if (response.data.code === 200) {
            commit("UPDATE_PASSWORD_SUCCESS", response.data.code);

            resolve({ status: true });
          } else {
            commit("UPDATE_PASSWORD_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return updatePassword;
  },

  editProfilGobus: ({ commit }, payload) => {
    
    console.log(payload)
    const updatePassword = new Promise((resolve, reject) => {
      APIPassenger.put(url.wsc.passenger.edit_profile_gobus, payload)
        .then(response => {
          if (response.data.code === 200) {
            commit("UPDATE_PASSWORD_SUCCESS", response.data.code);

            resolve({ status: true });
          } else {
            commit("UPDATE_PASSWORD_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return updatePassword;
  },
  GetDataById: ({commit}) => {
    

    const getData = new Promise((resolve, reject) => {
      APIPassenger.get(url.wsc.passenger.get_data_by_id)
        .then(response => {
          if (response.status == 200) {
            commit("SET_PASSENGER_DATA", response.data);
            resolve(response.data);
          } else {

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return getData;
  },
  getPaymentMethod: () => {
    

    const getPaymentMethod = new Promise((resolve, reject) => {
      APIPassenger.get(url.wsc.passenger.get_payment_method)
        .then(response => {
          if (response.status == 200) {

            resolve(response.data);
          } else {
            // commit("GET_PAYMENT_METHOD_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return getPaymentMethod;
  },

  getPaymentByCodeNumber: ({ commit }, payload) => {
    

    const getPaymentByCodeNumber = new Promise((resolve, reject) => {
      APIPassenger.post(url.wsc.passenger.get_payment, payload)
        .then(response => {
          if (response.status == 200) {
            commit("GET_OBJECT_PAYMENT_METHOD_SUCCESS", response.data.data);

            resolve(response.data);
          } else {
            commit("GET_OBJECT_PAYMENT_METHOD_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return getPaymentByCodeNumber;
  },

  checkKyc: ({ commit }) => {
    

    const checkKyc = new Promise((resolve, reject) => {
      APIPassenger.get(url.wsc.passenger.passenger_cek_kyc)
        .then(response => {
          if (response.status == 200) {
            commit("GET_CHECK_KYC", response.data.data);

            resolve({ status: true });
          } else {
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return checkKyc;
  },

  detailPassenger: () => {
    const detailPasseger = new Promise((resolve, reject) => {
      APIPassenger.get(url.wsc.passenger.detail_passenger)
        .then(response => {
          if (response.status == 200) {
            resolve(response.data.data);
          } else {
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return detailPasseger;
  },

  premiumChecking: () => {
    const detailPasseger = new Promise((resolve, reject) => {
      APIPassenger.get(url.wsc.passenger.premium_checking)
        .then(response => {
          if (response.status == 200) {
            resolve(response.data.data);
          } else {
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return detailPasseger;
  },

  GetQrCode: () => {
    const getQrCode = new Promise((resolve, reject) => {
      APIPassenger.get(url.wsc.passenger.qr_code)
        .then(response => {
          if (response.status == 200) {

            resolve(response.data.data);
          } else {
            // commit("GET_PAYMENT_METHOD_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return getQrCode;
  },
  

};
