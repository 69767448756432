import axios from "axios";
import store from "@/store/index";
import Cookies from "js-cookie";
const instance = axios.create();
instance.defaults.baseURL = process.env.VUE_APP_PROMOTION_API_URL
instance.defaults.headers["X-Requested-With"] = "XMLHttpRequest";
instance.defaults.headers.common['Authorization']= `Bearer ${Cookies.get('access_token')}`;
instance.defaults.withCredentials = true

instance.interceptors.request.use(
    response => {
      store.commit("auth/IS_LOADING", true);
  
      return response;
    },
    error => Promise.reject(error)
);

instance.interceptors.response.use(
    response => {
       store.commit("auth/IS_LOADING", false);
  
      return response;
    },
    async error => {
      const status = error.response ? error.response.status : null;
      const message = error.response?.data ? (error.response?.data?.message || "failed") : "failed";
      if (status === 401 && message.includes("expired")) {
        let refreshToken = await store.dispatch("auth/refreshTokenReq");
        if (refreshToken.status) {
          error.config.headers["Authorization"] = `Bearer ${Cookies.get('access_token')}`;
          
          return instance(error.config);
        }
      } else {
        const error_popup = {
          showPopup: true,
          errorMessage: message
        }
        store.commit("auth/SET_ERROR_POPUP", error_popup);
      }
      store.commit("auth/IS_LOADING", false);
      return Promise.reject(error);
    }
);

export default class APIDiscount {

    static get(path, options = {}) {
        return instance.get(path, { ...options });
    }

    static post(path, data = {}, options = {}) {
        return instance.post(path, data, { ...options });
    }
    
    static put(path, data = {}, options = {}) {
        return instance.put(path, data, { ...options });
    }
    
    static delete(path, options = {}) {
        return instance.delete(path, { ...options });
    }

    static all(path) {
        return instance.all(path);
    }
    
    static spread(param) {
        return instance.spread(param);
    }
    
    static delToken() {
        // console.log("success delete");
        delete axios.defaults.headers.common['Authorization'];
        delete instance.defaults.headers.common['Authorization'];
    }

    static setToken() {
        // console.log("success pasang");
        instance.defaults.headers.common['Authorization']= `Bearer ${Cookies.get('access_token')}`;
    }

    static download(path, data = {}) {
        path = path.replace(/([^:]\/)\/+/g, "$1");
        const downloadProcess = new Promise((resolve, reject) => {
          instance({
            url: path,
            method: "GET",
            responseType: "blob"
          })
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", data.name);
              document.body.appendChild(link);
              link.click();
              link.remove();
              resolve({ status: true });
            })
            .catch(e => {
              reject(e);
            });
        });
        return downloadProcess;
    }
}