!<template>
  <v-app-bar height=75 class="bar" :color="backgroundColorApp" dark flat dense app>
    <div v-if="isLoginV2">
      <div v-if="userRole === 'PARTNER-KALSEL'" class="d-flex justify-content-center">
        <v-img
          :src="require('../../assets/icon-tob/kemenhub-icon.png')"
          contain
          max-width="35px"
          class="mr-2"/>
        <v-img
          :src="require('../../assets/icon-tob/dishub-kalsel.png')"
          contain
          max-width="30px"/>
        <v-img
          :src="require('../../assets/icon-tob/acasia-icon.png')"
          contain
          max-width="80px"
          class="ml-16"/> 
      </div>
      <div v-else-if="userRole === 'PARTNER-TEST' || userRole === 'ULTIMATE'" class="d-flex justify-content-center">
        <v-img
          :src="require('../../assets/icon-tob/kemenhub-icon.png')"
          contain
          max-width="35px"
          class="mr-2"/>
        <v-img
          :src="require('../../assets/icon-tob/teman-bus-icon.png')"
          contain
          max-width="80px"/>
        <v-img
          :src="require('../../assets/icon-tob/acasia-icon.png')"
          contain
          max-width="80px"
          class="ml-16"/> 
      </div>
      <div v-else class="d-flex justify-content-center">
        <v-img
          :src="require('../../assets/acasialogo.png')"
          contain
          max-width="100px"
          class="mr-5"/>      
      </div>
    </div>
    <div v-else class="d-flex justify-content-center">
      <v-img
        :src="require('../../assets/acasialogo.png')"
        contain
        max-width="100px"
        class="mr-5"/>      
    </div>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      drawer: null,
      backgroundColorApp: '',
    };
  },
  computed: {
    isLoginV2() {
      return this.$store.getters["auth/getInitialPath"].login === "/login-v2";
    },
    userRole() {
      return localStorage.getItem('user_role')
    }
  },
  watch: {
    '$route': {
      handler: function() {
        this.backgroundColorApp = this.$partnerACL.getBackgroundColorApp();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
  },
};
</script>

<style>
.bar{
  /* display: flex;
  flex-wrap: wrap;
  align-content: center; */
  
  display: flex;
  align-items: center;
  justify-content: center;
}

.display-block {
  display: block;
  flex: 1;
}
</style>
