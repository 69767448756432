import APIMyTicket from "@/utils/apiMyTicket";
import { url } from '@/configs/apiUrl'

export default {
  gobusticket: ({ commit }, payload) => {
    const flatefare = new Promise((resolve, reject) => {
      APIMyTicket.post(url.wsc.my_ticket.list, payload)
        .then(response => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data);
            resolve(response.data);
          } else {
            commit("CREATE_FAILED", response.data.message);
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return flatefare;
  },
  flatefare: ({ commit }, payload) => {
    const flatefare = new Promise((resolve, reject) => {
      APIMyTicket.post(url.wsc.my_ticket.flatefare, payload)
        .then(response => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.data);
            resolve(response.data.data);
          } else {
            commit("CREATE_FAILED", response.data.message);
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return flatefare;
  },

  multidays: ({ commit }, payload) => {
    const multidays = new Promise((resolve, reject) => {
      APIMyTicket.post(url.wsc.my_ticket.multidays, payload)
        .then(response => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.data);
            resolve(response.data.data);
          } else {
            commit("CREATE_FAILED", response.data.message);
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return multidays;
  },
  studentfare: ({ commit }, payload) => {


    const multidays = new Promise((resolve, reject) => {
      APIMyTicket.post(url.wsc.my_ticket.studentfare, payload)
        .then(response => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.data);
            resolve(response.data.data);
          } else {
            commit("CREATE_FAILED", response.data.message);
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return multidays;
  },

  getTicketAvailability: ({ commit }, payload) => {
    const getGenerateTicket = new Promise((resolve, reject) => {
      APIMyTicket.get(url.wsc.my_ticket.get_ticket_availability + payload.invoiceNumber).then(response => {
        if (response.data.code == 200) {
          commit("CREATE_SUCCESS", response.data.data);
          resolve(response.data.data);
        } else {
          commit("CREATE_FAILED", response.data.message);
          resolve({ status: false });
        }
      }).catch(e => {
        reject(e);
      });
    });
    return getGenerateTicket;
  },
  // -----------------------------------------------------------------------------------------------------------------------------------------------------

};
