<template>
  <v-app id="inspire">
    <!-- <Navbar  v-if="isAuthenticated && isRoute == 'Home'" /> -->
    <Header v-if="isAuthenticated && isRoute == 'home'" />
    <HeaderAuth v-if="!isAuthenticated" />
    <HeaderBreadcrumb
      v-if="isAuthenticated && isRoute != 'Home'"
      :color="colorHeader"
      :colorIcon="iconColorHeader"
      :titleColor="titleColor"
    />
    <v-main class="body-color">
      <router-view></router-view>
      <v-overlay :value="isLoading">
        <v-progress-circular
          :value="80"
          :size="70"
          :width="7"
          color="green"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
      <notification-popup
        :dialog="popupError"
        :contentMsg="$t(errorMessage)"
        :headerMsg="$t('Failed')"
        :nameBtnNo="$t('labelNo')"
        :nameBtnYes="$t('Close')"
        colorIcon="danger"
        Icon="mdi-close-circle"
        disableBtnNo="true"
        imgSource="notification/failed.svg"
        :onHandlerYes="closePopup"
      />
      <notification-popup
        :dialog="sessionPopup"
        :contentMsg="$t('SessionExpired')"
        :headerMsg="$t('Failed')"
        :nameBtnNo="$t('labelNo')"
        :nameBtnYes="$t('Close')"
        colorIcon="danger"
        Icon="mdi-close-circle"
        disableBtnNo="true"
        imgSource="notification/failed.svg"
        :onHandlerYes="closeSession"
      />
    </v-main>
    <v-snackbar
      v-model="showSnackbar"
      :timeout="snackbarTimeout"
      top
    >
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          :color="$partnerACL.getButtonColor()"
          text
          style="font-weight: bold;"
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import Header from "./views/layout/Header.vue";
import HeaderAuth from "./views/layoutAuth/Header.vue";
import HeaderBreadcrumb from "./views/layout/HeaderBreadcrumb.vue";
import NotificationPopup from "@/components/NotificationPopup.vue";
import { get } from "vuex-pathify";

import firebase from "firebase/app";
import "firebase/messaging";

export default {
  components: { Header, HeaderAuth, HeaderBreadcrumb, NotificationPopup },
  data() {
    return {
      colorHeader: "",
      iconColorHeader: "",
      titleColor: "",
      drawer: null,
      routename: "",
      lang: ["id", "en", "vi"],
      showSnackbar: false,
      snackbarTimeout: (1000 * 60) * 5,
      snackbarText: '',
      sessionPopup: Boolean(localStorage.getItem('sessionDialog')) || false
    };
  },
  computed: {
    isAuthenticated: get("auth/isAuthenticated"),
    isRoute() {
      return this.$route.name;
    },
    isLoading: get("auth/isLoading"),
    popupError: get("auth/popupError"),
    errorMessage: get("auth/errorMessage"),
  },
  mounted() {
    this.closePopup()
  },
  async created() {
    this.routename = this.$route.name;
    try {
      const messaging = firebase.messaging()

      await messaging
        .requestPermission()
        .then(() => {
          console.log("Notification permission granted");

          return messaging.getToken()
        })
        .then(token => {
          console.log('Success generate FCM Token!!')
          localStorage.setItem('fcmToken', token)
        })
        .catch(err => {
          console.log(err)
        })
    } catch (error) {
      console.log(error);
    }
  },
  watch: {
    isRoute() {
      if (this.isRoute == "Ticket" || this.isRoute == "PremiumMember") {
        this.colorHeader = "primary";
        this.iconColorHeader = "white";
        this.titleColor = "h3-white";
      } else if (this.isRoute == "FlateFare" || this.isRoute == "MultiDays") {
        this.colorHeader = "white";
        this.iconColorHeader = "primary";
        this.titleColor = "h3-black";
      } else {
        this.colorHeader = "white";
        this.iconColorHeader = "primary";
        this.titleColor = "h3-black";
      }
    },
  },
  async updated() {
    try {
      const messaging = firebase.messaging()

      messaging.onMessage(payload => {
        this.showSnackbar = true
        this.snackbarText = payload.notification.body

        const request = {
          customer_id: this.$store.state.auth.userData.customerID,
          fcm_status_id: payload.fcmMessageId
        }
        this.$store
          .dispatch("notification/checkFcmStatus", request)
          .then((response) => {
            if (response.status) {
              this.$store.commit("notification/SET_MODAL_USAGE", true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    closePopup() {
      const error_popup = {
        showPopup: false,
        errorMessage: ""
      }
      this.$store.commit("auth/SET_ERROR_POPUP", error_popup);
    },
    closeSession() {
      this.sessionPopup = false
      localStorage.removeItem('sessionDialog')
    }
  },
};
</script>
<style >
.v-progress-circular {
  margin: 1rem;
}

.body-color {
  height: 100%;
  background-color: #f5fff7;
  width: 100%;
}
</style>

<!-- for global styling -->
<style lang="scss">
@import "assets/scss/global.scss";
</style>