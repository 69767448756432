import state from "./moduleOrchestratorState";
import mutations from "./moduleOrchestratorMutations";
import actions from "./moduleOrchestratorActions";
import getters from "./moduleOrchestratorGetters";

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
};
