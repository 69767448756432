<template>
  <v-app-bar class="appbar" color="primary" dark fixed flat dense app>
    <!-- <v-app-bar-nav-icon @click="openDrawer"></v-app-bar-nav-icon> -->

    <v-img
      :src="require('../../assets/acasialogo.png')"
      contain
      max-width="100px"
      class="mt-5 mb-5"
    />
    <v-spacer></v-spacer>
    <!-- <v-img @click="ClickPaymentMethod()" class="img-wallet"
        :src="require('../../assets/wallet.svg')"
        contain
          max-width="30px"
      /> -->
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      drawer: null,
      lang: ["id", "en", "vi"],
    };
  },
  methods: {
    openDrawer() {
      this.$store.commit("moduleUi/TOGGLE_DRAWER", true);
    },
    ClickPaymentMethod() {
      this.$router.push({
        name: "PaymentMethodList",
      });
    },
  },
};
</script>

<style>
.img-wallet {
  right: 4.13%;
  cursor: pointer;
}
.appbar {
  position: fixed;
  height: 300px;
}
</style>
