export const url = {
    wsc: {
        auth: {
            customer_login: "customer/login",
            get_partner_acl: "customer/get-partner-acl/",
            customer_get: "customer/get",
            customer_new_refresh_token: "customer/new_refresh_token",
            passenger_new_refresh_token: "passenger/new_refresh_token",
            customer_logout: "customer/logout",
            count_parental_access: "childlinkage/count-parental-access",
            customer_validate: "customer/validate",
            otp_reset: "otp/reset",
            partner_login: "passenger/partner/login",
            passenger_refresh_token: "passenger/refresh_token",
            forget_password: "customer/forget-password",
        },
        cdn: {
            file_upload: "api/v1/files/upload",
        },
        childlinkage: {
            reg: "childlinkage/reg",
            confirm: "childlinkage/confirm",
            delete: "childlinkage/delete",
            list_school: "childlinkage/list-school",
            list: "childlinkage/list",
            reg_list: "childlinkage/req-list",
            detail: "childlinkage/detail",
            passenger_sof_delete: "passenger-sof/delete",
            passenger_sof_edit: "passenger-sof/edit",
            get_payment: "passenger-sof/get-payment"
        },
        mpm: {
            validate: "ticket/mpm/validate"
        },
        cpm: {
            generate_qr: "ticket/cpm/generate-qr",
            validate: "ticket/cpm/validate"
        },
        customer: {
            register: "customer/register",
            get: "customer/get",
            qr_code: "customer/qr-code",
            profile_checking: "customer/membership/status/check",
            get_profile: "customer/membership/profile/get/",
            get_profile_requirement: "profile/requirement/form/getlist",
            check_parent_email: "customer/membership/parent/check/",
            membership_req: "customer/membership/request",
            membership_cancel: "customer/membership/cancel",
            profile_edit: "customer/edit",
            profile_change_password: "customer/change-password",
            passenger_sof_get: "customer-sof/get",
            customer_sof_create: "customer-sof/create",
            get_payment_by_code_number: "customer-sof/get-payment",
            sof_payment_delete: "customer-sof/delete",
            sof_payment_edit: "customer-sof/edit",
            set_primary: "customer-sof/set-primary",
            notification_getlist: "customer-data/notification/getlist",
            get_stat_sub_notif: "customer/get-subs-notif",
            subscribe_notif: "customer/subscribe-notif",
            list_dw_membership: "customer/membership/dropdown/get",
            list_payment_channel: "customer/list-payment-channel/"
        },
        discount: {
            discount_redeem_list: "discount-redeem/list",
            discount_redeem_validation: "discount-redeem/validation"
        },
        menu_management: {
            static_content_wsc_by_id_1: "static-content/wsc/get/1?enable=true",
            static_content_wsc_by_id_2: "static-content/wsc/get/2?enable=true",
            get_helper_center: "faq/pwa/get?faq_enable=true",
            translations: "translations"
        },
        my_ticket: {
            list: "myticket/list",
            flatefare: "myticket/flatefare",
            multidays: "myticket/multidays",
            studentfare: "myticket/studentfare",
            get_ticket_availability: "myticket/check-generate-ticket/"
        },
        notification: {
            fcm_notif: "notification/check-fcm-notif"
        },
        passenger: {
            create_payment_method: "passenger-sof/create",
            register: "passenger/register",
            passenger_kyc_create: "passenger-kyc/create",
            create_passenger_premium: "passenger-kyc/create-premium",
            selfie_id_cam: "passenger-kyc/selfieid-cam",
            selfie_cam: "passenger-kyc/selfie-cam",
            delete_payment_method: "passenger-sof/delete",
            change_password_passenger: "passenger/change-password",
            reset_password: "passenger/reset-password",
            edit_sof_edit: "passenger-sof/edit",
            set_primary: "passenger-sof/set-primary",
            promo_create: "passenger-promo/create",
            get_promo_used: "passenger-promo/used",
            get_promo_available: "passenger-promo/available",
            edit_profile: "passenger/edit",
            register_edit_profile_gobus: "passenger/register-edit-gobus",
            edit_profile_gobus: "passenger/edit-gobus",
            get_data_by_id: "passenger/get",
            get_payment_method: "passenger-sof/get",
            get_payment: "passenger-sof/get-payment",
            passenger_cek_kyc: "passenger-kyc/cek-kyc",
            detail_passenger: "passenger/get",
            premium_checking: "passenger-kyc/cek-kyc",
            qr_code: "passenger/qr-code",
        },
        payment: {
            alepay: "payment/alepay/",
            alepay_atm_card: "payment/alepay-atm-card/",
            momo: "payment/momo/",
            moca: "payment/moca/",
            send_order_alepay: "payment/alepay/send-order/",
            payment_status: "payment/status/",
            payment_detail: "payment/details/",
            list_peyment_channel: "payment/partner-payment-channels/",
            payment_cancel: "payment/alepay/callback/cancel/",
            pay_as_you_go_mpm: "payment/payasugo/mpm",
            pay_as_you_go_cpm: "payment/payasugo/cpm",
            payg_generate_qr: "payment/payasugo/generateqr",
            list_partner_payment_channel: "payment/partner-payment-channels/",
            alepay_callback_cancel: "payment/alepay/callback/cancel/",
        },
        purchase_ticket: {
            check_available_cart_otomatis: "checkout/checkAvailableCartOtomatis",
            show_cart: "cart/showcart",
            add_cart: "cart/addtocart",
            select_cart: "cart/selectcart",
            select_all_cart: "cart/selectallcart",
            delete_cart: "cart/delitem",
            update_qty: "cart/updateqty",
            payment_create: "payment/create",
            payment_update: "invoice/update",
            qty_cart: "cart/qtycart",
            qty_my_ticket: "cart/qtymyticket",
            purhasing_id_history: "purchasehistory",
            purchasing_detail_history: "purchasehistory/detail",
            payment_token_status: "payment/status?payment_token=",
            status_by_order_id: "payment/status/orderid?order_id=",
            get_status_by_invoice: "payment/invoice-status/",
            flatfare_list: "cart/flatefare",
            multidays_list: "cart/multidays"
        },
        my_trip: {
            list: "myticket/triphistory"
        },
        orchestrator: {
            request_payment: "request-payment",
            list_payment_channel: "payment-channels/list/",
            payment_detail: "payment/details/",
            transaction_detail: "payment/transactions/details/"
        }
    },

}
