import APIMPM from "@/utils/apiMpmValidate";
import { url } from '@/configs/apiUrl'

export default {

    GenerateQR: ({ commit }, payload) => {
        const validate = new Promise((resolve, reject) => {
            APIMPM.post(url.wsc.cpm.generate_qr, payload)
                .then(response => {
                    // console.log("respone from API",response)
                    if (response.data.code == 200) {
                        commit("CREATE_SUCCESS", response.data.code);
                        resolve({ status: true, data: response.data.data });
                    } else {
                        resolve({ message: response.data.message });
                        commit("CREATE_SUCCESS", response.data.code);
                        commit("CREATE_FAILED", response.data.message);
                    }
                })
                .catch(e => {
                    reject(e);
                });
        });

        return validate;
    },
};
