import APIMPM from "@/utils/apiMpmValidate";
// import store from "@/store/index";
import { url } from '@/configs/apiUrl'

export default {

  validate: ({ commit },payload) => {
    

    const validate = new Promise((resolve, reject) => {
      APIMPM.post(url.wsc.mpm.validate, payload)
        .then(response => {
          // console.log("respone from API",response)
          if (response.data.code == 200) {
              commit("VALIDATE_RESULT", response.data.code);
              resolve({ status: true });
          } else {
              resolve({message: response.data.message});
              commit("VALIDATE_RESULT", response.data.code);
              commit("VALIDATE_MESSAGE", response.data.message);
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return validate;
  },
 
// -----------------------------------------------------------------------------------------------------------------------------------------------------

};
