const state = function () {
  return {
    isAuthenticated: false,
    userData: {},
    parentalAccess: "",
    auth_inactive: false,
    auth_inactive_payload_temp: {},
    user_role: "",//0=PASSENGER-BASIC, 1=PASSENGER-PARRENTS, 2=PASSENGER-STUDENT, 3= PREMIUM-MEMBER
    isLoading: false,
    provider: "",
    securityWarning: false,
    menuUser: [],
    partner: null,
    profile: null,
    popupError: false,
    errorMessage: "",
    initialPath:null
    // userMerchantData: {},
    // accessToken: {},
    // userMerchantId: 0,
    // userOutletId: 0
  };
};

export default state;
