<template>
    <v-btn 
      v-if="active"
      :small="small" 
      :rounded ="rounded"
      :outlined ="outlined"
      :fab ="fab"
      :color="type == 'cancel' ? '#cf142b' : $partnerACL.getButtonColor()"
      elevation="0"
      v-on:click="onClickHandler"
      :class="[classBtn, {'white--text':!outlined,}]"
      class="text-capitalize"
      :disabled="isDisabled"
      :width="width"
    >
      <div class="normal-white-space">{{name}}</div>
    </v-btn>
</template>

<script>
    export default{
        name:"acbutton",
    props:{
      active:{
            type: Boolean,
            default: true
      },
      name: {
            type: String,
            required: true
      },
      width: {
            type: String,
            required: false,
            default:'100%'
      },
      // color:{
      //       type: String,
      //       default: 'primary'
      // },
      classBtn:{
            default:'',
            type: String
      },
      onClickHandler:{
            type: Function,
      },
      small:{
            type: Boolean,
            default: false
      },
      rounded:{
            type: Boolean,
            default: true
      },
      outlined:{
            type: Boolean,
            default: false
      },
      fab:{
            type: Boolean,
            default: false
      },
      isDisabled:{
            type: Boolean,
            default: false
      },
      type: {
            type: String,
            default: null
      }

    }
  }    
</script>
<style lang="scss" scoped>

.normal-white-space {
      white-space: normal;
      word-break: break-word;
}
</style>
