import { Number } from "core-js";

const state = function () {
    return {
        isResponse: Number,
        requestPayment: {}
    };
};

export default state;
