
export default {
  CREATE_IDENTITY_CARD(state, val) {
    state.photo_identiti_card = val;
  },
  CREATE_IDENTITY_CARD_FAILED(state, val) {
    state.isMsg.message = val;
  },
  CREATE_SELFIE_CARD(state, val){
    state.photo_selfie_id = val;

  },
  CREATE_SELFIE(state, val){
    state.photo_selfie = val;

  },

};
