import axios from "axios";
import store from "@/store/index";
import Cookies from "js-cookie";

const instance = axios.create();
instance.defaults.baseURL = process.env.VUE_APP_CDN_API_URL;
instance.defaults.headers["Content-Type"] = "multipart/form-data";
instance.defaults.headers.common['Authorization']= `Bearer ${Cookies.get('access_token')}`;
instance.interceptors.request.use(
  response => {
    store.commit("auth/IS_LOADING", true);
    
    // if (!response.headers.Authorization) {
    //   // const token = store.getters["authentication/getAccessToken"];
    //   const token = store.state.authentication.accessToken.token;

    //   if (token) {
    //     response.headers.Authorization = `Bearer ${token}`;
    //   }
    // }

    return response;
  },
  error => Promise.reject(error)
);

instance.interceptors.response.use(
  response => {
     store.commit("auth/IS_LOADING", false);
    // commit("AUTH_INACTIVE", false);
    // const refreshToken = store.getters["authentication/getRefreshToken"];
    // if (refreshToken) {
    //   const tokenDecoded = decode(refreshToken);
    //   const expiredDate = tokenDecoded.exp;
    //   const currentDate = Math.floor(Date.now() / 1000);

    //   if (expiredDate > currentDate) {
    //     store.dispatch("authentication/refreshTokenReq");
    //   }
    // }

    return response;
  },
  async error => {
    console.log(error);
    const status = error.response ? error.response.status : null;
    const message = error.response?.data ? (error.response?.data?.message || "failed") : "failed";
    if (status === 401 && message.includes("expired")) {
      let refreshToken = await store.dispatch("auth/refreshTokenReq");
      if (refreshToken.status) {
        // const token = store.state.auth.accessToken.token;
        // error.config.headers["Authorization"] = `Bearer ${token}`;
        error.config.headers["Authorization"] = `Bearer ${Cookies.get('access_token')}`;
        // error.config.baseURL = undefined;

        // return Promise.resolve(axios.request(error.config));
        return instance(error.config);
      }
    } else if (status === 401 && message.includes("Not allowed")) {
      Cookies.set('loggedIn', 'false')
      store.dispatch("auth/logout")
        .then(() => {
          store.dispatch("auth/resetInitialPath", JSON.parse(localStorage.getItem("InitialPath")))
          localStorage.setItem('sessionDialog', 'true')
          location.reload()
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const error_popup = {
        showPopup: true,
        errorMessage: message
      }
      store.commit("auth/SET_ERROR_POPUP", error_popup);
    }
    store.commit("auth/IS_LOADING", false);
    return Promise.reject(error);
  }
);

instance.defaults.headers["X-Requested-With"] = "XMLHttpRequest";
export default class APICDN {
  static get(path, options = {}) {
    return instance.get(path, { ...options });
  }

  static post(path, data = {}, options = {}) {
    return instance.post(path, data, { ...options });
  }

  static put(path, data = {}, options = {}) {
    return instance.put(path, data, { ...options });
  }

  static delete(path, options = {}) {
    return instance.delete(path, { ...options });
  }

  static all(path) {
    return instance.all(path);
  }

  static spread(param) {
    return instance.spread(param);
  }

  static delToken() {
    delete instance.defaults.headers.common['Authorization'];
  }

  static setToken() {
    instance.defaults.headers.common['Authorization']= `Bearer ${Cookies.get('access_token')}`;
  }

  static download(path, data = {}) {
    path = path.replace(/([^:]\/)\/+/g, "$1");
    const downloadProcess = new Promise((resolve, reject) => {
      instance({
        url: path,
        method: "GET",
        responseType: "blob"
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", data.name);
          document.body.appendChild(link);
          link.click();
          link.remove();
          resolve({ status: true });
        })
        .catch(e => {
          reject(e);
        });
    });
    return downloadProcess;
  }
}


