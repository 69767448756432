import { Number } from "core-js";

const state = function() {
  return {
    isResponse: Number,
    userUpdatePassword: {},
    ListPaymentMethod: [],
    getPaymentMethod: {},
    successPayment:{},
    checkKyc:[],
    isMsg: String,
    passengerData:{}
  };
};

export default state;
