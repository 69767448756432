import { Number } from "core-js";

const state = function() {
  return {
    isResponse: Number,
    userUpdatePassword: {},
    ListPaymentMethod: [],
    getPaymentMethod: {},
    successRegStudent:{},
    isMsg: String
  };
};

export default state;
