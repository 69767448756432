import APIOrchestrator from "@/utils/apiOrchestrator";
import { url } from '@/configs/apiUrl'

export default {
    requestPayment: ({ commit }, payload) => {
        const request = new Promise((resolve, reject) => {
            APIOrchestrator.post(url.wsc.orchestrator.request_payment, payload).then(response => {
                if (response.data.code == 200) {
                    commit("SET_RESPONSE", response.data.data);
                    resolve({ status: true, data: response.data.data });
                } else {
                    resolve({ message: response.data.message });
                }
            })
                .catch(e => {
                    reject(e)
                })
        });
        return request;
    },

    listPaymentChannel: ({ commit }, payload) => {
        const list = new Promise((resolve, reject) => {
            APIOrchestrator.get(url.wsc.orchestrator.list_payment_channel + payload.partner_id).then(response => {
                if (response.data.code == 200) {
                    commit("SET_RESPONSE", response.data.data)
                    resolve({ status: true, data: response.data.data })
                } else {
                    resolve({ message: response.data.message })
                }
            })
                .catch(e => {
                    reject(e)
                })
        })

        return list
    },

    paymentDetails: ({ commit }, payload) => {
        const details = new Promise((resolve, reject) => {
            APIOrchestrator.get(url.wsc.orchestrator.payment_detail + payload).then(response => {
                if (response.data.code == 200) {
                    commit("SET_RESPONSE", response.data.data)
                    resolve({ status: true, data: response.data.data })
                } else {
                    resolve({ message: response.data.message })
                }
            })
                .catch(e => {
                    reject(e)
                })
        })

        return details
    },

    transactionDetails: ({ commit }, payload) => {
        const trxDetails = new Promise((resolve, reject) => {
            APIOrchestrator.get(url.wsc.orchestrator.transaction_detail + payload.transaction_id).then(response => {
                if (response.data.code == 200) {
                    commit("SET_RESPONSE", response.data.data)
                    resolve({ status: true, data: response.data.data })
                } else {
                    resolve({ message: response.data.message })
                }
            })
                .catch(e => {
                    reject(e)
                })
        })

        return trxDetails
    }
}