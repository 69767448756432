import Cookies from "js-cookie";
// import Browser from "@/utils/Browser";
import APIChildlinkage from "@/utils/apiChildlinkage";
import APIOTP from "@/utils/apiOTP";
import APIPassenger from "@/utils/apiPassenger";
import APICustomer from "@/utils/apiCustomer";
import API from "@/utils/authApi";
import store from "@/store/index";
import router from '@/router/index'
import i18n from "@/i18n/i18n";
import { version } from '@/../package.json';
import APIMenuManagement from "@/utils/apiMenuManagement";
import { url } from '@/configs/apiUrl'

export default {
  login: ({ commit, dispatch }, payload) => {
    const authenticateLogin = new Promise((resolve, reject) => {
      API.post(url.wsc.auth.customer_login, payload).then(async response => {

        if (response.data.code === 200) {
          if (response.data.data.user.status !== "inactive") {
            // dispatch function for storing partner acl configuration
            dispatch('storePartnerACL', response.data.data.partner_acl)
            localStorage.setItem("partner_id", response.data.data.partner.partnerID);
            if (payload.remember_me == true) {
              commit("AUTHENTICATION_SUCCESS30", response.data.data);
              commit("PASSSENGER_STATUS", response.data.data.user.status);
              commit("PROVIDER", response.data.data.user.provider);
              commit("SET_PARTNERINFO", response.data.data.partner);
              commit("SET_PROFILEINFO", response.data.data.profile);
              resolve({ status: true });
            }
            if (payload.remember_me == false) {
              commit("AUTHENTICATION_SUCCESS", response.data.data);
              commit("PASSSENGER_STATUS", response.data.data.user.status);
              commit("PROVIDER", response.data.data.user.provider);
              commit("SET_PARTNERINFO", response.data.data.partner);
              commit("SET_PROFILEINFO", response.data.data.profile);
              resolve({ status: true });
            }

            // dispatch('getCountStudent');
          } else if (response.data.data.user.status === "inactive") {
            // commit("AUTHENTICATION_FAILED", false);
            commit("AUTH_INACTIVE", { status: true, auth: payload });
            resolve({
              status: "inactive",
              email: response.data.data.user.email,
              customer_user_id: response.data.data.user.customerUserID,
            });
          } else {
            commit("AUTHENTICATION_FAILED", false);
            resolve({
              status: false,
              message:
                "Maaf, anda tidak diizinkan untuk mengakses website ini"
            });
          }

          // Get Translation Data
          APIMenuManagement.get(url.wsc.menu_management.translations).then((result) => {
            localStorage.setItem("translationData", JSON.stringify(result.data.data.language));
            let translationData = result.data.data.language

            // Set the translation data in the VueI18n instance for all supported languages
            Object.keys(translationData).forEach(language => {
              i18n.setLocaleMessage(language, translationData[language]);
            });
          });
        } else if (response.data.message_code === 110) {
          commit("SECURITY_WARNING", true);
          resolve({
            message: "securityWarning"
          });
        }
        else {
          commit("AUTHENTICATION_FAILED", false);
          resolve({ status: false });
        }
      })
        .catch(e => {
          const response = e.response
          if (response.data.data.message_code === 110) {
            commit("SECURITY_WARNING", true);
            resolve({
              message: "securityWarning"
            });
          }
          reject(e);
        });
    });
    return authenticateLogin;
  },
  getPartnerACL: async ({ dispatch }, payload) => {
    const partneracl = await new Promise((resolve, reject) => {
      const partner_id = payload.partner_id;
      API.get(url.wsc.auth.get_partner_acl + partner_id)
        .then(response => {
          if (response.data.code == 200) {
            dispatch('storePartnerACL', response.data.data)
            resolve({ status: true, data: response.data.data })
          } else {
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });
    return partneracl;
  },
  storePartnerACL: async ({ commit }, payload) => {
    const aclConfig = payload.role_additional;
    localStorage.setItem("PartnerACLConfiguration", aclConfig);
    localStorage.setItem('language', JSON.parse(aclConfig).default_language || 'en');
    localStorage.setItem('InitialPath', JSON.stringify(payload.initial_path));
    i18n.locale = JSON.parse(aclConfig).default_language || 'en';

    commit("SET_INITIALPATH", payload.initial_path);
    commit("SET_ROLENAME", payload.role_id);
    const buttonListActive = payload.button_list_active;
    const subMenuListActive = payload.sub_menu_list_active;
    var menuListTemp = await payload.menu_list.map(rmenu => {
      return {
        ...rmenu,
        id: rmenu.menu_id,
        label: rmenu.menu_name,
        name: rmenu.menu_name,
        button_list: rmenu.button_list ? (JSON.parse(rmenu.button_list)).map(btn => {
          const currentActiveButton = buttonListActive.find(btnActive => btnActive.button_id == btn.button_id && rmenu.menu_id == btnActive.menu_id)
          return {
            ...currentActiveButton,
            is_active: currentActiveButton ? true : false,
            // menu_redirect: currentActiveButton ? this.menuRedirectListOptions.find(fmrmenu=>currentActiveButton.menu_id_redirect == fmrmenu.menu_id ) : '',
          }
        }) : [],
        sub_menu_list: rmenu.sub_menu_list ? (JSON.parse(rmenu.sub_menu_list)).map(submenu => {
          const currentActiveSubMenu = subMenuListActive.find(submenuActive => submenuActive.sub_menu_id == submenu.sub_menu_id && rmenu.menu_id == submenuActive.menu_id)
          return {
            ...currentActiveSubMenu,
            is_active: currentActiveSubMenu ? true : false,
          }
        }) : [],
      }
    });

    var subMenuList = await payload.sub_menu_list_active
      .filter(fsubmenu => !((menuListTemp.map(mmenu => mmenu.id)).includes(fsubmenu.sub_menu_id)))
      .map(rsubmenu => {
        return {
          ...rsubmenu,
          id: rsubmenu.sub_menu_id,
          label: rsubmenu.sub_menu_name,
          name: rsubmenu.sub_menu_name,
          menu_name: rsubmenu.sub_menu_name,
          menu_path: rsubmenu.sub_menu_path,
          menu_component: rsubmenu.sub_menu_component,
          menu_meta: rsubmenu.sub_menu_meta,
        }
      });

    const menuList = menuListTemp.concat(subMenuList);
    commit("MENU_FOR_USER", menuList);

    for (let menu of menuList) {
      var metaData = JSON.parse(menu.menu_meta)
      metaData.component_rules = {
        button_list: menu.button_list || [],
        sub_menu_list: menu.sub_menu_list || []
      }
      var routeData = {
        path: menu.menu_path,
        name: menu.menu_name,
        component: () => import('@/views' + menu.menu_component + '.vue'),
        meta: metaData,
      }

      router.addRoutes([routeData])
    }

  },
  refreshCustomerUserData: ({ commit }) => {
    const detailCustomer = new Promise((resolve, reject) => {
      APICustomer.get(url.wsc.auth.customer_get)
        .then(response => {
          if (response.status == 200) {
            resolve(response.data.data);
            commit('SET_USERDATA', response.data.data)
            commit("PASSSENGER_STATUS", response.data.data.status);
          } else {
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });
    return detailCustomer;
  },
  refreshTokenReq: ({ commit }) => {
    const refreshNewToken = new Promise((resolve, reject) => {
      API.post(url.wsc.auth.customer_new_refresh_token, { refresh_token: Cookies.get("refresh_token") })
        .then(response => {
          if (response.data.code == 200) {
            commit("AUTHENTICATION_SUCCESS", response.data.data);
            // commit("PASSSENGER_STATUS", response.data.data.roles.role);
            // commit("PROVIDER", response.data.data.provider);

            commit("AUTHENTICATION_SUCCESS", response.data.data);
            commit("PASSSENGER_STATUS", response.data.data.user.status);
            commit("PROVIDER", response.data.data.user.provider);
            commit("SET_PARTNERINFO", response.data.data.partner);
            commit("SET_PROFILEINFO", response.data.data.profile);
            resolve({ status: true });
          } else {
            commit("AUTHENTICATION_FAILED", response.data.message);
            store.commit("purchaseticket/GETQTYTICKET_FAILED");
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return refreshNewToken;
  },
  refreshTokenBP: ({ commit }, payload) => {

    const refreshNewToken = new Promise((resolve, reject) => {
      API.post(url.wsc.auth.passenger_new_refresh_token, { refresh_token: payload.rToken })
        .then(response => {
          if (response.data.code == 200) {
            commit("AUTHENTICATION_SUCCESS", response.data.data);
            commit("PASSSENGER_STATUS", response.data.data.roles.role);
            commit("PROVIDER", response.data.data.provider);
            resolve({ status: true });
          } else {
            commit("AUTHENTICATION_FAILED", response.data.message);
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return refreshNewToken;
  },
  logout: ({ commit }) => {
    const logoutReq = new Promise((resolve, reject) => {
      API.post(url.wsc.auth.customer_logout, { refresh_token: Cookies.get("refresh_token") })
        .then(response => {
          if (response.data.code == 200) {
            resolve({ status: true });
          } else {
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    commit("LOGOUT_REQ", false);

    return logoutReq;
    // commit("user/CLEAR_STATE", null, { root: true });
  },
  updateUser: ({ commit }, payload) => {
    const updateUserData = new Promise((resolve, reject) => {
      API.put(`/passenger/user/${payload.user_id}`, {})
        .then(response => {
          if (response.data.status_code === "200") {
            commit("UPDATE_USER_SUCCESS", response.data.data);

            resolve({ status: true });
          } else {
            commit("UPDATE_USER_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return updateUserData;
  },
  // getUserData: ({ commit }, payload) => {
  //   const storeUserMerchantData = commit("SET_UPDATED_MERCHANT", payload);
  //   return storeUserMerchantData;
  // },
  // getUserMerchantData: ({ commit }, payload) => {
  //   const storeUserMerchantData = commit("SET_UPDATED_MERCHANT", payload);
  //   return storeUserMerchantData;
  // },
  // storeUserMerchantData: ({ commit }, payload) => {
  //   console.log("storeUserMerchant " + payload);
  //   const storeUserMerchantData = commit("SET_UPDATED_MERCHANT", payload);
  //   return storeUserMerchantData;
  // },
  storeUserMerchantId: ({ commit }, payload) => {
    return commit("UPDATE_MERCHANT_ID", payload.id);
  },
  storeUserOutletId: ({ commit }, payload) => {
    return commit("UPDATE_OUTLET_ID", payload.id);
  },

  getCountStudent: ({ commit }) => {
    const getStudent = new Promise((resolve, reject) => {
      APIChildlinkage.get(url.wsc.auth.count_parental_access)
        .then(response => {
          if (response.data.code == 200) {
            // commit("PARENTAL_ACCESS", response.data.data.count_child);

            if (response.data.data.count_child > 0) {
              commit("PARENTAL_ACCESS", "parent");
            }
            resolve(response.data.data);
          } else {
            // commit("CREATE_FAILED", response.data.message);
            if (response.data.data.count_child < 1) {
              commit("CREATE_FAILED", "child");
            }
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return getStudent;
  },
  otpVerify: ({ commit }, payload) => {
    const dataPayload = {
      ...payload,
      pic: "2#" + version + "#" + payload.customer_user_id
    }
    const otpVerify = new Promise((resolve, reject) => {
      APIPassenger.post(url.wsc.auth.customer_validate, dataPayload)
        .then(response => {
          if (response.data.code == 200) {
            commit("OTPVERIFY_SUCCESS", response.data.message);
            resolve({ status: true });
          } else {
            commit("OTPVERIFY_SUCCESS", 0);
            resolve({ message: response.data.message });
          }
        })
        .catch(e => {
          reject(e);
        });
    });
    return otpVerify;
  },
  resendOTP: ({ commit }, payload) => {
    const resendOTP = new Promise((resolve, reject) => {
      APIOTP.post(url.wsc.auth.otp_reset, payload)
        .then(response => {
          if (response.data.code == 200) {
            commit("RESENDOTP_SUCCESS", response.data.data);
            resolve({ status: true });
          } else {
            commit("RESENDOTP_SUCCESS", 0);
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });
    return resendOTP;
  },
  storeIsLoading: ({ commit }, val) => {
    return commit("IS_LOADING", val);
  },

  loginByGobus: async ({ commit, dispatch }, payload) => {
    await dispatch('logout').then(() => {
      const authenticateLogin = new Promise((resolve, reject) => {
        API.post(url.wsc.auth.partner_login, payload).then(response => {
          if (response.data.code === 200) {
            dispatch('refreshTokenLoginByGobus', response.data.data).then((response) => {
              if (response.status == true) {
                resolve({ status: true });
              } else {
                resolve({ status: true });
              }
            }).catch((err) => {
              console.log(err);
            })
            // if (response.data.data.roles.group_roles === "PASSENGER" && response.data.data.roles.role !== "inactive") {
            //   // console.log(payload)
            //   if (payload.remember_me==true) {
            //     commit("AUTHENTICATION_SUCCESS30", response.data.data);
            //     commit("PASSSENGER_STATUS", response.data.data.roles.role);

            //     resolve({ status: true });
            //   }
            //   if (payload.remember_me==false) {
            //     commit("AUTHENTICATION_SUCCESS", response.data.data);
            //     commit("PASSSENGER_STATUS", response.data.data.roles.role);

            //     resolve({ status: true });
            //   }


            //   dispatch('getCountStudent');
            // } else if(response.data.data.roles.role === "inactive"){
            //     // commit("AUTHENTICATION_FAILED", false);
            //     commit("AUTH_INACTIVE", true);
            //     resolve({ status:"inactive" ,
            //               email: response.data.data.user.user_email,
            //               user_id: response.data.data.user.user_id,
            //   });

            // } else {
            //     commit("AUTHENTICATION_FAILED", false);
            //     resolve({
            //       status: false,
            //       message:
            //         "Maaf, anda tidak diizinkan untuk mengakses website ini"
            //     });
            // }
          } else {
            commit("AUTHENTICATION_FAILED", false);
            resolve({ status: false });
          }
        })
          .catch(e => {
            reject(e);
          });
      });

      return authenticateLogin;
    })

  },

  refreshTokenLoginByGobus: ({ commit }, payload) => {
    console.log(payload)
    const refreshToken = new Promise((resolve, reject) => {
      API.post(url.wsc.auth.passenger_refresh_token, { refresh_token: payload.refresh_token })
        .then(response => {
          // console.log(response)
          if (response.data.code == 200) {
            // console.log(response)
            commit("AUTHENTICATION_SUCCESS", response.data.data);
            commit("PASSSENGER_STATUS", response.data.data.roles.role);
            commit("PROVIDER", response.data.data.provider);
            resolve({ status: true });
          } else {
            commit("AUTHENTICATION_FAILED", response.data.message);
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return refreshToken;
  },

  resetInitialPath: ({ commit }, payload) => {
    return commit("SET_INITIALPATH", payload);
  },

  forgetPassword: (_, payload) => {
    const forgetPassword = new Promise((resolve, reject) => {
      API.post(url.wsc.auth.forget_password, payload)
        .then(response => {
          if (response.data.code == 200) {
            resolve({ status: true, data: response.data.data });
          } else {
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e)
        })
    })

    return forgetPassword;
  }
};
