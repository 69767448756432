<template>
  <v-app-bar class="appbar" :color="$partnerACL.getBackgroundColorApp()" fixed flat dense app>
    <v-icon v-if="isShowBackButton" cols="9" @click="back()" :color="$partnerACL.getButtonColor()">mdi-arrow-left</v-icon>

    <!-- <v-toolbar-title :class="titleColor + ' ml-3 font-weight-bold'"> -->
    <v-toolbar-title class="ml-3 font-weight-bold h3" :style="`color: ${$partnerACL.getTitleColor()}!important`">
      {{ $t(isNameBreadcrumb) }}</v-toolbar-title
    >
    
    <div
      class="h3-black"
      light
      plain
      elevation="0"
      v-if="thisRoute == 'MyTicket' || thisRoute == 'MyTicketGobus'"
    >
      <span v-if="countTicket > 0" class="font-weight-bold" :style="`color: ${$partnerACL.getTitleColor()}!important`"
        >({{ countTicket }})</span
      >
    </div>
    <div
      class="h3-black"
      light
      plain
      elevation="0"
      v-else-if="thisRoute == 'gobusmyticket'"
    >
      <span v-if="countTicketBus > 0" class="font-weight-bold" :style="`color: ${$partnerACL.getTitleColor()}!important`"
        >({{ countTicketBus }})</span
      >
    </div>    

    <v-spacer></v-spacer>

    <!-- <div
      class="buttonright"
      light
      plain
      elevation="0"
      @click="clickCart()"
      v-if="
        (thisRoute == 'FlateFare' ||
        thisRoute == 'MultiTrips' ||
        thisRoute == 'Ticket' ||
        thisRoute == 'StudentFare') && !user_role.toUpperCase().endsWith('-BLACKLIST')
      "
    > -->
    <div
      v-if="isShowCartButton"
      class="mr-4"
      light
      plain
      elevation="0"
      @click="clickCart()"
      style="position: relative;"
    >
      <v-icon
        class="img-union"
        :color="$partnerACL.getButtonColor()"
        >fas fa-bag-shopping
      </v-icon>
      <span class="index cursor-pointer" :style="`color: ${$partnerACL.getTitleColor()}!important`">{{ countCart }}</span>
    </div>
  </v-app-bar>
</template>

<script>
import store from "@/store";

export default {
  props: {
    colorIcon: {
      required: true,
      type: String,
      default: "primary",
    },
    color: {
      required: true,
      type: String,
      default: "#FFFFFF",
    },
    titleColor: {
      required: true,
      type: String,
      default: "h3-white",
    },
  },
  data() {
    return {
      isShowBackButton: false,
      backButtonRedirect: null,
      isShowCartButton: false,
      cartButtonRedirect: null,
      
    }
  },
  computed: {
    thisRoute() {
      return this.$route.name;
    },
    routePath() {
      return this.$route.path;
    },
    metaRoute() {
      return this.$route.meta;
    },
    componentButtonList() {
      return this.metaRoute.component_rules ? this.metaRoute.component_rules.button_list : [] ;
    },
    isNameBreadcrumb() {
      if (this.metaRoute.breadcrumb) {
        return this.metaRoute.breadcrumb[1].title;
      } else if(this.metaRoute.pageTitle) {
        return this.metaRoute.pageTitle;
      } else {
        return "";
      }
    },
    countCart() {
      return store.state.purchaseticket.countCart || 0;
    },

    customerID() {
      return store.state.auth.userData.customerID;
    },
    countTicket() {
      return store.state.purchaseticket.countTicket;
    },
    countTicketBus() {
      return store.state.purchaseticket.countTicketBus;
    },
    user_role() {
      return store.state.auth.user_role;
    },
  },
  watch: {
    '$route': {
      handler: function() {
        this.isShowBackButton = this.isShowButton("back");
        this.backButtonRedirect = this.getDetailButton("back");
        this.isShowCartButton = this.isShowButton("cart");
        this.cartButtonRedirect = this.getDetailButton("cart");
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    isShowButton(buttonName) {
      return this.$partnerACL.findActiveButton(this.componentButtonList,buttonName) ? this.$partnerACL.findActiveButton(this.componentButtonList,buttonName).is_active : false;
    },
    getDetailButton(buttonName) {
      return this.$partnerACL.findActiveButton(this.componentButtonList,buttonName) ? this.$partnerACL.findActiveButton(this.componentButtonList,buttonName) : null;
    },
    clickHome() {
      this.$router.replace({
        name: "Home",
      });
    },
    clickCart() {
      const routeName = this.$route.name
      
      if(routeName==='Ticket'){
        // this.$router.push({
        //   name: "Cart",
        // });
        if(this.cartButtonRedirect){
          if(this.cartButtonRedirect.menu_type == "wsc_menu"){
            this.$router.push({ path: this.cartButtonRedirect.redirect_path });
          }else {
            window.location = this.cartButtonRedirect.redirect_path;
          }
        }
      }else{
        // this.$router.replace({
        //   name: "Cart",
        // });
        if(this.cartButtonRedirect){
          if(this.cartButtonRedirect.menu_type == "wsc_menu"){
            this.$router.replace({ path: this.cartButtonRedirect.redirect_path });
          }else {
            window.location = this.cartButtonRedirect.redirect_path;
          }
        }
      }
    },
    back() {
      if (this.thisRoute == "Cart" || this.thisRoute == "ScanQR") {
        this.$router.go(-1);
      }
      else if(this.backButtonRedirect){
        if(this.backButtonRedirect.menu_type == "wsc_menu"){
          if(this.$route.params.data){
            this.$router.replace({ path: this.backButtonRedirect.redirect_path, params: {data : this.$route.params.data} });
          }{
            this.$router.replace({ path: this.backButtonRedirect.redirect_path });
          }
        }else {
          window.location = this.backButtonRedirect.redirect_path;
        }
      }
      else if (this.thisRoute == "PaymentMethodList") {
        this.$router.replace({ name: "About" });
      } else if (
        this.thisRoute == "Ticket" ||
        this.thisRoute == "MyTicket" ||
        this.thisRoute == "MyTrip" ||
        this.thisRoute == "TransactionHistory" ||
        this.thisRoute == "PremiumMember" ||
        this.thisRoute == "PremiumMember" ||
        this.thisRoute == "About" ||
        this.thisRoute == "gobusmyticket" ||
        this.thisRoute == "MyTripInHome" || 
        this.thisRoute == "MyTicketGobus"  ||
        this.thisRoute == "PaymentStatus" 
      ) {
        this.$router.replace({ name: "Homepage" });
      } else if (this.thisRoute == "RegisterUpdate") {
        this.$router.replace({ name: "CloseApp" });
      } else if(this.thisRoute == "Checkout"){
        this.$router.replace({ name: "Cart" });
      } else if (this.thisRoute == "FlateFare" || this.thisRoute == "MultiTrips"
        || this.thisRoute == "Cart") {
        this.$router.replace({ name: "Ticket" });
      } else if (this.thisRoute == "DoPayment") {
        this.$router.replace({ name: "Checkout" });
      } else {
        this.$router.go(-1);
      }
    },
  },
  beforeRouteUpdate() {
    if(this.isShowCartButton) {
      const payload = {
        customerID: this.customerID,
      };
      this.$store.dispatch("purchaseticket/getQtyCart", payload);
    }
  },
  beforeRouteEnter() {
    if(this.isShowCartButton) {
      const payload = {
        customerID: this.customerID,
      };
      this.$store.dispatch("purchaseticket/getQtyCart", payload);
    }
  },
  updated() {
    if(this.isShowCartButton) {
      const payload = {
        customerID: this.customerID,
      };
      this.$store.dispatch("purchaseticket/getQtyCart", payload);
    }
  },
  updatedTicket() {
    const payload = {
      customerID: this.customerID,
    };
    this.$store.dispatch("purchaseticket/getQtyTicket", payload);
  },
};
</script>


<style scoped>
.appbar {
  height: 50px !important;
  position: fixed;
}
.h3-black {
  margin-left: 2%;
  font-size: 20px;
  color: #000000;
}

.h3 {
  font-size: 20px;
}

/* .buttonright {
} */
.buttonright-ticket {
  display: none;
}

.h3-white {
  margin-left: 2%;
  font-size: 20px;
  color: #ffffff;
}

.h3-green {
  margin-left: 2%;
  font-size: 20px;
  color: #4bb14e;
  color: linear-gradient(180deg, #45a648 0%, #4bb14e 100%);
}

.icon-arrow-left {
  color: linear-gradient(180deg, #45a648 0%, #4bb14e 100%);
}
.img-union {
  cursor: pointer;
  font-weight: bold;
  font-size: 2.075rem;
  position: absolute !important;
  top: 50%;  /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  /* padding: 5px 8px;
  position: absolute;
  right: 30px;
  top: 6px; */
  /* margin-top: -5px;
  margin-right: 20px; */
}
.count-cart {
  color: #d63305, 100%;
  background-color: #d63305, 100%;
}
.index {
  z-index: 8;
  color: #d63305;
  font-size: 9px;
  font-weight: bold;
  position: absolute !important;
  top: 50%;  /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -10%);
  /* padding: 8px 8px;
  position: absolute; */
  /* right: 36px;
  top: 15px; */
}
.cursor-pointer {
  cursor: pointer;
}
</style>