import state from "./moduleMenuManagementState.js";
import mutations from "./moduleMenuManagementMutations.js";
import actions from "./moduleMenuManagementActions.js";
import getters from "./moduleMenuManagementGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
