<template>
  <v-row justify="center">
    <v-dialog
      :value="dialog"
      @input="$emit('input')"
      persistent
      max-width="300"
    >
      <v-card class="round-20 px-2">
        <!--  -->
        <v-card
          :class="typeModal == 'card' ? 'd-block' : 'd-none'"
          max-width="400"
          class="mx-auto round-lg"
        >
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <v-card :color="colorCard" dark>
                  <v-card-title class="headline">
                    {{ titleheadlineCard }}
                  </v-card-title>

                  <v-card-subtitle>{{ contentHeadlineCard }}</v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <!--  -->
        <div align="center" class="d-block">
          <v-icon
            v-if="imgSource == null"
            class="icon-margin"
            size="100"
            :color="colorIcon"
            >{{ Icon }}</v-icon
          >
          <img
            v-if="!(imgSource == null)"
            class="img-popup"
            :src="require(`@/assets/${imgSource}`)"
          />
        </div>
        <div align="center" class="pa-1" :class="titlePadding">
          <span :class="colorheaderMsg" class="title-content">
            {{ headerMsg }}
          </span>
        </div>
        <hr
          v-if="typeModal == 'dialog' || typeModal == 'none'"
          style="visibility: hidden"
        />
        <v-card-text :align="contentMsgAlign" class="px-1 pb-0" :class="contentPadding">
          <h4 class="font-weight-regular my-2">{{ contentMsg }}</h4>
          <!-- show if the message more than 1 line -->
          <span  v-for="(message,i) in contentMsgList" :key="i">
            <h4 class="font-weight-regular my-2">{{ message }}</h4>
          </span>
          <!-- show if the message more than 1 line -->
        </v-card-text>
        <v-card-actions v-if="disableActions == false">
          <v-row v-if="disableBtnNo == 'true'">
            <v-col align="center">
              <v-spacer></v-spacer>
              <ac-button 
                :outlined="true"
                :name="$t(nameBtnYes)" 
                :on-click-handler="clickOke"
              />
            </v-col>
          </v-row>

          <v-row v-if="disableBtnNo == 'false'">
            <v-col align="center" cols="6">
              <v-spacer></v-spacer>
              <ac-button 
                :outlined="true"
                :name="$t(nameBtnNo)" 
                :on-click-handler="clickNo"
              />
            </v-col>
            <v-col align="center" cols="6">
              <ac-button 
                :name="$t(nameBtnYes)" 
                :on-click-handler="clickOke"
              />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

// HATI HATI PERBEDAAN ANTARA DIALOGS DAN DIALOG
// PAKAI CARA INI UNTUK MENGHINDARI MUTASI PROPS LANGSUNG

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

import AcButton from "@/components/AcButton";

export default {
  name: "notificationPopUp",
  components: {
    AcButton
  },
  props: {
    colorheaderMsg: {
      type: String,
      default: "black--text",
    },
    headerMsg: {
      /*
        Header Title Message
      */
      type: String,
      required: true,
      default: "Open Dialog",
    },
    contentMsgAlign: {
      /*
        Description message
      */
      type: String,
      required: false,
      default: "center",
    },
    contentMsg: {
      /*
        Description message
      */
      type: String,
      required: true,
      default: "Dialog content here.",
    },
    contentMsgList: {
      /*
        Description messages --> if the message more than 1 line
      */
      type: Array,
      required: false,
      default: ()=>[],
    },
    nameBtnNo: {
      /*
        name button No
      */
      type: String,
      required: true,
      default: "No",
    },
    nameBtnYes: {
      /*
        name button Yes
      */
      type: String,
      required: true,
      default: "Yes",
    },

    outlinedBorderBtnNo: {
      /*
        name button Yes
      */
      type: Boolean,
      required: false,
      default: true,
    },
    Icon: {
      /*
        name material design icon
      */
      type: String,
      required: false,
      // default:"mdi-check-circle"
    },
    colorIcon: {
      /*
        color material design icon
      */
      type: String,
      required: false,
      default: "#fffff",
    },
    typeModal: {
      /*
        type modal notification 
        1 = transaction
        2 = card 
        3 = dialog
        if point 3(dialog) default none
      */
      type: String,
      required: false,
      default: "none",
    },
    colorCard: {
      /*
        set color card
      */
      type: String,
      required: false,
      default: "#385F73",
    },
    titleheadlineCard: {
      /*
        set title card
      */
      type: String,
      required: false,
      default: "Title Card",
    },
    contentHeadlineCard: {
      /*
        set description card
      */
      type: String,
      required: false,
      default: "Content Headline Card",
    },
    // Img: {
    //   type: String,
    //   required: false,
    // },
    disableActions: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableBtnNo: {
      type: String,
      required: false,
      default: "false",
    },
    onHandlerYes: {
      /*
        set handler function after click oke/ yes
      */
      type: Function,
      required: false,
    },
    onHandlerNo: {
      /*
        set handler function after click no
      */
      type: Function,
      required: false,
    },
    dialog: {
      /*
        set open dialog
      */
      type: Boolean,
      required: true,
    },
    imgSource: {
      type: String,
    },
  },
  data() {
    return {
      dialogs: this.dialog,
      titlePadding: "",
      contentPadding: "",
    };
  },
  created() {
    if (this.typeModal == "dialog" || this.typeModal == "none") {
      this.titlePadding = "";
      this.contentPadding = "pt-3 pb-3";
    } else {
      this.titlePadding = "pb-0";
      this.contentPadding = "";
    }
  },
  methods: {
    clickOke() {
      this.onHandlerYes();
    },
    clickNo() {
      this.dialogs = false;
      this.onHandlerNo();
    },
  },
  mounted() {},
};
</script>
<style>
.v-btn--contained {
  box-shadow: 0px 6px 2px -4px rgb(14 14 44 / 10%),
    inset 0px -1px 0px rgb(14 14 44 / 10%);
}
</style>
<style lang="scss" scoped>
.round-20 {
  border-radius: 20px !important;
}
.border-no-btn {
  border: 1px solid rgba(75, 177, 78, 0.2);
}
.img-popup {
  margin-top: 30px;
  width: 100px;
}
hr {
  width: 90%;
  margin: auto;
  background: #4bb14e;
  height: 1px;
  border: none;
  opacity: 0.2;
}
.title-content {
  font-size: 20px;
  font-weight: 700;
}
/* .btn-action{
  margin-left: 10%;
} */

.icon-margin {
  margin-top: 10%;
}
</style>