import state from "./moduleDiscountState.js";
import mutations from "./moduleDiscountMutations.js";
import actions from "./moduleDiscountActions.js";
import getters from "./moduleDiscountGetters.js";

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
};
