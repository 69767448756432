import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import i18n from "./i18n/i18n";
import { ApmVuePlugin } from "@elastic/apm-rum-vue";
import { partnerACL } from './utils/partnerACL.js'
import firebase from 'firebase/app'
import 'firebase/messaging'
import APIMenuManagement from '@/utils/apiMenuManagement'
// import { message } from './firebase'
import { url } from '@/configs/apiUrl'

firebase.initializeApp({
  apiKey: "AIzaSyBdAeijp3DyA91hW7Okh1CE2u5944i1vSI",
  authDomain: "acasia-dd2c9.firebaseapp.com",
  projectId: "acasia-dd2c9",
  storageBucket: "acasia-dd2c9.appspot.com",
  messagingSenderId: "815199258288",
  appId: "1:815199258288:web:4c9c3e5af0b7088d215d75",
  measurementId: "G-RR930F488T"
});

navigator.serviceWorker
  .register("firebase-messaging-sw.js")
  .then((registration) => {
    const messaging = firebase.messaging();
    messaging.useServiceWorker(registration);
  })
  .catch((err) => {
    console.log(err);
  });

Vue.prototype.$partnerACL = partnerACL

// // VeeValidate
import VeeValidate, { Validator } from 'vee-validate';
import messagesEn from 'vee-validate/dist/locale/en.js';
import messagesVi from 'vee-validate/dist/locale/vi.js';
import messagesId from 'vee-validate/dist/locale/id.js';
import attrsVi from '@/locales/vi.json';
import attrsEn from '@/locales/en.json';
// define dictionary for vee validate
const dictEn = {
  name: "en",
  messages: {
    ...messagesEn.messages,
    max: (field, [length]) => `Maximum ${length} characters`,
    confirmed: (field) => `The ${field} does not match`,
    email: () => `Invalid email address`,
  },
  attributes: attrsVi
}
const dictVi = {
  name: "vi",
  messages: {
    ...messagesVi.messages,
    max: (field, [length]) => `tối đa ${length} ký tự`,
    confirmed: (field) => `${field} không khớp`,
    email: () => `địa chỉ email không hợp lệ`,
  },
  attributes: attrsEn
}

Vue.use(VeeValidate);
const defaultLang = localStorage.getItem('language') || "en";
if (defaultLang == 'en') {
  Validator.localize('en', dictEn)
} else if (defaultLang == 'vi') {
  Validator.localize('vi', dictVi)
}
// variable for global
Vue.prototype.$messagesEn = dictEn
Vue.prototype.$messagesVi = dictVi


Vue.config.productionTip = false

Vue.mixin({
  data: function () {
    return {
      get urlWebSocket() {
        return process.env.VUE_APP_API_URL_SOCKET
      },
      get portWebSocket() {
        return parseInt(process.env.VUE_APP_API_SOCKET_PORT)
      },
      get mqttUsername() {
        return process.env.VUE_APP_MQTT_USERNAME
      },
      get mqttPassword() {
        return process.env.VUE_APP_MQTT_PASSWORD
      },
      get mqttUseSSL() {
        switch (process.env.VUE_APP_MQTT_USE_SSL) {
          case 'true':
            return true
          default:
            return false
        }
      },
    }
  }
})

Vue.use(ApmVuePlugin, {
  router,
  config: {
    serviceName: 'acasia-web-sales',
    serverUrl: 'https://fe6f8efb839a4dcbbefae419311fabb9.apm.asia-southeast1.gcp.elastic-cloud.com:443',
    serviceVersion: '0.1.0',
    environment: 'dev'
  }
})

// global helpers function
import helpers from './helpers/utilities';
Vue.use({
  install() {
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
  }
});

async function fetchTranslationData() {
  try {
    // Fetch translation data from the API for all supported languages
    let translationData;
    if (localStorage.getItem("translationData") === null || localStorage.getItem("translationData") === "") {
      let response = await APIMenuManagement.get(url.wsc.menu_management.translations);
      translationData = response.data.data.language
      localStorage.setItem("translationData", JSON.stringify(response.data.data.language));
    } else {
      translationData = JSON.parse(localStorage.getItem("translationData"))
    }

    let attrsVi = translationData.vi
    let attrsEn = translationData.en
    let attrsId = translationData.id

    // define dictionary for vee validate
    const dictEn = {
      name: "en",
      messages: {
        ...messagesEn.messages,
        max: (field, [length]) => `The ${field} maximum ${length} characters`,
      },
      attributes: attrsEn
    }
    const dictVi = {
      name: "vi",
      messages: {
        ...messagesVi.messages,
        max: (field, [length]) => `${field} tối đa ${length} ký tự`,
      },
      attributes: attrsVi
    }
    const dictId = {
      name: "id",
      messages: {
        ...messagesId.messages,
        max: (field, [length]) => `${field} maksimum ${length} karakter`,
      },
      attributes: attrsId
    }


    Vue.use(VeeValidate);
    const defaultLang = localStorage.getItem('language') || "en";
    if (defaultLang == 'en') {
      Validator.localize('en', dictEn)
    } else if (defaultLang == 'vi') {
      Validator.localize('vi', dictVi)
    } else if (defaultLang == 'id') {
      Validator.localize('id', dictId)
    }
    // variable for global
    Vue.prototype.$messagesEn = dictEn
    Vue.prototype.$messagesVi = dictVi
    Vue.prototype.$messagesId = dictId

    // Set the translation data in the VueI18n instance for all supported languages
    Object.keys(translationData).forEach(language => {
      i18n.setLocaleMessage(language, translationData[language]);
    });


    // Set the default language in the VueI18n instance
    const defaultLanguage = localStorage.getItem('language') || 'en';
    i18n.locale = defaultLanguage;

    Vue.prototype.$partnerACL = partnerACL
  } catch (error) {
    console.error('Failed to fetch translation data:', error);
  }
}

fetchTranslationData()

const vue = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')

export default vue;