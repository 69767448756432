import Cookies from "js-cookie";
// import Browser from "@/utils/Browser";
import stateAuth from "./moduleAuthState";
import APICDN from "@/utils/apiCdn";
import APIChildlinkage from "@/utils/apiChildlinkage";
import APICustomer from "@/utils/apiCustomer";
import APIDiscount from "@/utils/apiDiscount";
import APIMenuManagement from "@/utils/apiMenuManagement"
import APIMpmValidate from "@/utils/apiMpmValidate";
import APIMyTicket from "@/utils/apiMyTicket";
import APIMyTrip from "@/utils/apiMyTrip";
import APIOTP from "@/utils/apiOTP";
import APIPassenger from "@/utils/apiPassenger";
import APIPurchaseTicket from "@/utils/apiPurchaseTicket";

export default {
  AUTHENTICATION_SUCCESS(state, payload) {
    state.isAuthenticated = true;
    state.userData = payload.user;
    // state.accessToken = payload.access_token;
    Cookies.set("access_token", payload.access_token.token);
    Cookies.set("refresh_token", payload.access_token.refresh_token, {
      expires: 1,
    });
    Cookies.set("loggedIn", "true", {
      expires: 1,
    });
    APICDN.setToken();
    APIChildlinkage.setToken();
    APIMpmValidate.setToken();
    APICustomer.setToken();
    APIDiscount.setToken();
    APIMyTicket.setToken();
    APIMyTrip.setToken();
    APIOTP.setToken();
    APIPassenger.setToken();
    APIPurchaseTicket.setToken();
    APIMenuManagement.setToken();
  },
  AUTHENTICATION_SUCCESS30(state, payload) {
    state.isAuthenticated = true;
    state.userData = payload.user;
    // state.accessToken = payload.access_token;
    Cookies.set("access_token", payload.access_token.token);
    Cookies.set("refresh_token", payload.access_token.refresh_token, {
      expires: 30,
    });
    Cookies.set("loggedIn", "true", {
      expires: 30,
    });
    APICDN.setToken();
    APIChildlinkage.setToken();
    APIMpmValidate.setToken();
    APICustomer.setToken();
    APIDiscount.setToken();
    APIMyTicket.setToken();
    APIMyTrip.setToken();
    APIOTP.setToken();
    APIPassenger.setToken();
    APIPurchaseTicket.setToken();
    APIMenuManagement.setToken();
  },
  AUTHENTICATION_FAILED(state, payload) {
    state.isAuthenticated = payload;
  },
  SET_IS_AUTHENTICATED(state, payload) {
    state.isAuthenticated = payload;
  },
  SECURITY_WARNING(state, payload) {
    state.securityWarning = payload;
  },
  AUTH_INACTIVE(state, payload) {
    state.auth_inactive = payload.status;
    state.auth_inactive_payload_temp = payload.auth;
  },
  LOGOUT_REQ(state, payload) {
    state.isAuthenticated = payload;
    const initialAuth = stateAuth();
    Object.keys(initialAuth).forEach((key) => {
      state[key] = initialAuth[key];
    });
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    Cookies.remove("loggedIn");
    localStorage.removeItem("vuex");
    localStorage.removeItem("menu_user");

    APICDN.delToken();
    APIChildlinkage.delToken();
    APIMpmValidate.delToken();
    APICustomer.delToken();
    APIDiscount.delToken();
    APIMyTicket.delToken();
    APIMyTrip.delToken();
    APIOTP.delToken();
    APIPassenger.delToken();
    APIPurchaseTicket.delToken();
    APIMenuManagement.delToken();
  },
  RESET_ALL_DATA(state) {
    state.isAuthenticated = false;
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    Cookies.remove("loggedIn");
    localStorage.removeItem("vuex");

    APICDN.delToken();
    APIChildlinkage.delToken();
    APIMpmValidate.delToken();
    APIMyTicket.delToken();
    APIMyTrip.delToken();
    APIOTP.delToken();
    APIPassenger.delToken();
    APIPurchaseTicket.delToken();
    APIMenuManagement.delToken();
  },
  SET_UPDATED_USER_INFO(state, payload) {
    state.userData.first_name = payload.firstName;
    state.userData.last_name = payload.lastName;
  },
  PARENTAL_ACCESS(state, val) {
    state.parentalAccess = val;
  },
  PASSSENGER_STATUS(state, val) {
    state.user_role = val;
  },
  IS_LOADING(state, val) {
    state.isLoading = val;
  },
  PROVIDER(state, val) {
    state.provider = val
  },
  MENU_FOR_USER(state, val) {
    state.menuUser = val
    localStorage.setItem('menu_user', JSON.stringify(val));
  },
  SET_PARTNERINFO(state, val) {
    state.partner = val
  },
  SET_PROFILEINFO(state, val) {
    state.profile = val
  },
  SET_USERDATA(state, val) {
    state.userData = val
  },
  SET_ERROR_POPUP(state, val) {
    state.popupError = val.showPopup
    state.errorMessage = val.errorMessage
  },
  SET_INITIALPATH(state, val) {
    state.initialPath = val
  },
  SET_ROLENAME(state, val) {
    state.user_role = val
    localStorage.setItem('user_role', val);
  }
};
