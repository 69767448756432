import Cookies from "js-cookie";
import { isEmpty } from "lodash";

export default {
  getIsAuthentication: state => {
    return state.isAuthenticated;
  },
  getAccessToken: state => {
    return isEmpty(Cookies.get("access_token"))
      ? state.accessToken.token
      : Cookies.get("access_token");
  },
  getRefreshToken: state => {
    return isEmpty(Cookies.get("refresh_token"))
      ? state.accessToken.refresh_token
      : Cookies.get("refresh_token");
  },
  getUserData: state => {
    return state.userData;
  },
  getUserMerchantData: state => {
    return state.userMerchantData;
  },
  getUserMerchantId: state => {
    return state.userMerchantId;
  },
  getUserOutletId: state => {
    return state.userOutletId;
  }
};
