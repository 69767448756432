import state from "./modulePurchaseTicketState.js";
import mutations from "./modulePurchaseTicketMutations.js";
import actions from "./modulePurchaseTicketActions.js";
import getters from "./modulePurchaseTicketGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
