
export default {
  TOGGLE_DRAWER(state,payload) {
    state.toggleDrawer = payload;
  },
  ROUTE_INFORMATION(state,payload) {
    state.routeInformation = payload;
  },
  CHECKOUT(state, payload){
  state.checkout(payload) 
  },
  MINUSQTY(state, payload){
  state.minusQty(payload) 
  },
  PLUSQTY(state, payload){
  state.plusQty(payload) 
  },
}
