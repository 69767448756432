import ApiPayment from "@/utils/apiPayment";
import ApiPurchaseTicket from "@/utils/apiPurchaseTicket";
import { url } from '@/configs/apiUrl'

export default {
    choosePaymentAlepay: ({ commit }, params) => {
        const paymentSelected = new Promise((resolve, reject) => {
            ApiPayment.post(url.wsc.payment.alepay+ params.token, params.payload).then(response => {
                commit("SET_RESPONSE", response.data);
                resolve(response.data);
            })
                .catch(e => {
                    reject(e)
                })
        });
        return paymentSelected;
    },

    choosePaymentAlepayATMCard: ({ commit }, params) => {
        const paymentSelected = new Promise((resolve, reject) => {
            ApiPayment.post(url.wsc.payment.alepay_atm_card + params.token, params.payload).then(response => {
                commit("SET_RESPONSE", response.data);
                resolve(response.data);
            })
                .catch(e => {
                    reject(e)
                })
        });
        return paymentSelected;
    },

    choosePaymentMomo: ({ commit }, params) => {
        const paymentSelected = new Promise((resolve, reject) => {
            ApiPayment.post(url.wsc.payment.momo + params.token, params.payload).then(response => {
                commit("SET_RESPONSE", response.data);
                resolve(response.data);
            })
                .catch(e => {
                    reject(e)
                })
        });
        return paymentSelected;
    },

    choosePaymentMoca: ({ commit }, params) => {
        const paymentSelected = new Promise((resolve, reject) => {
            ApiPayment.post(url.wsc.payment.moca + params.token, params.payload).then(response => {
                commit("SET_RESPONSE", response.data);
                resolve(response.data);
            })
                .catch(e => {
                    reject(e)
                })
        });
        return paymentSelected;
    },

    sendOrderAlepay: ({ commit }, params) => {
        const sendOrder = new Promise((resolve, reject) => {
            ApiPayment.post(url.wsc.payment.send_order_alepay + params.token, params.payload).then(response => {
                commit("SET_RESPONSE", response.data);
                resolve(response.data);
            })
                .catch(e => {
                    reject(e)
                })
        });
        return sendOrder;
    },

    paymentStatus: ({ commit }, token) => {
        const status = new Promise((resolve, reject) => {
            ApiPayment.get(url.wsc.payment.payment_status + token).then(response => {
                commit("SET_RESPONSE", response.data);
                resolve(response.data);
            })
                .catch(e => {
                    reject(e)
                })
        });
        return status;
    },

    paymentDetail: ({ commit }, token) => {
        const status = new Promise((resolve, reject) => {
            ApiPayment.post(url.wsc.payment.payment_detail + token).then(response => {
                commit("SET_RESPONSE", response.data);
                resolve(response.data);
            })
                .catch(e => {
                    reject(e)
                })
        });
        return status;
    },

    listPartnerPaymentChannels: ({ commit }) => {
        const status = new Promise((resolve, reject) => {
            ApiPayment.post(url.wsc.payment.list_partner_payment_channel + 1).then(response => {
                commit("SET_RESPONSE", response.data);
                resolve(response.data);
            })
                .catch(e => {
                    reject(e)
                })
        });
        return status;
    },

    paymentCancel: ({ commit }, token) => {
        const status = new Promise((resolve, reject) => {
            ApiPayment.post(url.wsc.payment.alepay_callback_cancel + token).then(response => {
                commit("SET_RESPONSE", response.data);
                resolve(response.data);
            })
                .catch(e => {
                    reject(e)
                })
        });
        return status;
    },

    paygMpm: ({ commit }, payload) => {
        const status = new Promise((resolve, reject) => {
            ApiPurchaseTicket.post(url.wsc.payment.pay_as_you_go_npm, payload).then(response => {
                if (response.data.code == 200) {
                    commit("SET_RESPONSE", response.data);
                    resolve({ status: true, data: response.data });
                } else {
                    resolve({ message: response.data.message });
                }
            })
                .catch(e => {
                    reject(e)
                })
        });
        return status;
    },

    paygCpm: ({ commit }, payload) => {
        const status = new Promise((resolve, reject) => {
            ApiPurchaseTicket.post(url.wsc.payment.pay_as_you_go_cpm, payload).then(response => {
                if (response.data.code == 200) {
                    commit("SET_RESPONSE", response.data);
                    resolve({ status: true, data: response.data });
                } else {
                    resolve({ message: response.data.message });
                }
            })
                .catch(e => {
                    reject(e)
                })
        });
        return status;
    },

    paygGenerateQR: ({ commit }, payload) => {
        const status = new Promise((resolve, reject) => {
            ApiPurchaseTicket.post(url.wsc.payment.payg_generate_qr, payload).then(response => {
                if (response.data.code == 200) {
                    commit("SET_RESPONSE", response.data);
                    resolve({ status: true, data: response.data });
                } else {
                    resolve({ message: response.data.message });
                }
            })
                .catch(e => {
                    reject(e)
                })
        });
        return status;
    },
}