import APICustomer from "@/utils/apiCustomer";
import store from "@/store/index";
import { version } from '@/../package.json';
import { url } from '@/configs/apiUrl'

export default {
  register: ({ commit }, payload) => {
    const dataPayload = {
      ...payload,
      pic: "2#" + version + "#" + payload.username
    }
    const registerCustomer = new Promise((resolve, reject) => {
      APICustomer.post(url.wsc.customer.register, dataPayload)
        .then(response => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.data);

            resolve({ status: true });
          } else {
            commit("CREATE_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return registerCustomer;
  },

  detailCustomer: () => {
    const detailCustomer = new Promise((resolve, reject) => {
      APICustomer.get(url.wsc.auth.customer_get)
        .then(response => {
          if (response.status == 200) {
            resolve(response.data.data);
          } else {
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return detailCustomer;
  },


  GetQrCode: () => {
    const getQrCode = new Promise((resolve, reject) => {
      APICustomer.get(url.wsc.customer.qr_code)
        .then(response => {
          if (response.status == 200) {

            resolve(response.data.data);
          } else {
            // commit("GET_PAYMENT_METHOD_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return getQrCode;
  },

  profileChecking: (_, payload) => {
    const checkProfileEKYC = new Promise((resolve, reject) => {
      APICustomer.post(url.wsc.customer.profile_checking, payload)
        .then(response => {
          if (response.status == 200) {
            resolve(response.data.data);
          } else {
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return checkProfileEKYC;
  },

  getProfileDetail: (_, id) => {
    const getProfileDetail = new Promise((resolve, reject) => {
      APICustomer.get(url.wsc.customer.get_profile + id)
        .then(response => {
          if (response.status == 200) {
            resolve(response.data.data);
          } else {
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return getProfileDetail;
  },

  getProfileRequirements: () => {
    const checkProfileEKYC = new Promise((resolve, reject) => {
      APICustomer.get(url.wsc.customer.get_profile_requirement)
        .then(response => {
          if (response.status == 200) {
            resolve(response.data.data);
          } else {
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return checkProfileEKYC;
  },

  checkParentEmail: (_, email) => {
    const checkParentEmail = new Promise((resolve, reject) => {
      APICustomer.get(url.wsc.customer.check_parent_email + email)
        .then(response => {
          if (response.status == 200) {
            resolve(response.data.data);
          } else {
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return checkParentEmail;
  },

  membershipRequest: ({ commit }, payload) => {
    const dataPayload = {
      ...payload,
      // identityFile: JSON.stringify(payload.identityFile),
      pic: "2#" + version + "#" + store.state.auth.userData.customerUserID
    }
    const registerCustomer = new Promise((resolve, reject) => {
      APICustomer.post(url.wsc.customer.membership_req, dataPayload)
        .then(response => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.data);

            resolve({ status: true });
          } else {
            commit("CREATE_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return registerCustomer;
  },

  membershipCancel: (_, payload) => {
    const dataPayload = {
      ...payload,
      pic: "2#" + version + "#" + store.state.auth.userData.customerUserID
    }
    const registerCustomer = new Promise((resolve, reject) => {
      APICustomer.post(url.wsc.customer.membership_cancel, dataPayload)
        .then(response => {
          if (response.data.code == 200) {
            resolve({ status: true });
          } else {
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return registerCustomer;
  },

  editProfile: (_, payload) => {
    const dataPayload = {
      ...payload,
      pic: "2#" + version + "#" + store.state.auth.userData.customerUserID
    }
    const updateCustomer = new Promise((resolve, reject) => {
      APICustomer.put(url.wsc.customer.profile_edit, dataPayload)
        .then(response => {
          if (response.data.code === 200) {

            resolve({ status: true });
          } else {

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return updateCustomer;
  },
  changePassword: (_, payload) => {
    const dataPayload = {
      ...payload,
      pic: "2#" + version + "#" + store.state.auth.userData.customerUserID
    }
    const updatePassword = new Promise((resolve, reject) => {
      APICustomer.put(url.wsc.customer.profile_change_password, dataPayload)
        .then(response => {
          if (response.data.code === 200) {
            resolve({ status: true });
          } else {
            resolve({ status: false, message: response.data.message });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return updatePassword;
  },


  getPaymentMethod: () => {
    const getPaymentMethod = new Promise((resolve, reject) => {
      APICustomer.get(url.wsc.customer.passenger_sof_get)
        .then(response => {
          if (response.status == 200) {

            resolve(response.data);
          } else {
            // commit("GET_PAYMENT_METHOD_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return getPaymentMethod;
  },
  addPaymentMethod: (_, payload) => {
    const dataPayload = {
      ...payload,
      pic: "2#" + version + "#" + store.state.auth.userData.customerUserID
    }
    const getPaymentMethod = new Promise((resolve, reject) => {
      APICustomer.post(url.wsc.customer.customer_sof_create, dataPayload)
        .then(response => {
          if (response.status == 200) {

            resolve(response.data);
          } else {
            // commit("GET_PAYMENT_METHOD_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return getPaymentMethod;
  },

  getlistPaymentChannel: (_, payload) => {
    const getPaymentChannel = new Promise((resolve, reject) => {
      APICustomer.get(url.wsc.customer.list_payment_channel + payload.partner_id)
        .then(response => {
          if (response.status == 200) {

            resolve(response.data);
          } else {
            // commit("GET_PAYMENT_METHOD_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return getPaymentChannel;
  },

  getPaymentByCodeNumber: ({ commit }, payload) => {
    const getPaymentByCodeNumber = new Promise((resolve, reject) => {
      APICustomer.post(url.wsc.customer.get_payment_by_code_number, payload)
        .then(response => {
          if (response.status == 200) {
            commit("GET_OBJECT_PAYMENT_METHOD_SUCCESS", response.data.data);

            resolve(response.data);
          } else {
            commit("GET_OBJECT_PAYMENT_METHOD_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return getPaymentByCodeNumber;
  },
  sofPaymentDelete: ({ commit }, payload) => {
    const dataPayload = {
      ...payload,
      pic: "2#" + version + "#" + store.state.auth.userData.customerUserID
    }

    const deletePaymentMethod = new Promise((resolve, reject) => {
      APICustomer.put(url.wsc.customer.sof_payment_delete, dataPayload)
        .then(response => {
          if (response.data.code == 200) {
            commit("DELETE_PAYMENT_METHOD_SUCCESS", response.data.code);

            resolve({ status: true });
          } else {
            commit("DELETE_PAYMENT_METHOD_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return deletePaymentMethod;
  },

  editPaymentMethod: ({ commit }, payload) => {
    const dataPayload = {
      ...payload,
      pic: "2#" + version + "#" + store.state.auth.userData.customerUserID
    }

    const editPaymentMethod = new Promise((resolve, reject) => {
      APICustomer.put(url.wsc.customer.sof_payment_edit, dataPayload)
        .then(response => {
          if (response.data.code === 200) {
            commit("EDIT_PAYMENT_METHOD_SUCCESS", response.data.code);

            resolve({ status: true });
          } else {
            commit("EDIT_PAYMENT_METHOD_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return editPaymentMethod;
  },

  setPrimary: ({ commit }, payload) => {
    const dataPayload = {
      ...payload,
      pic: "2#" + version + "#" + store.state.auth.userData.customerUserID
    }
    const setPrimary = new Promise((resolve, reject) => {
      APICustomer.put(url.wsc.customer.set_primary, dataPayload)
        .then(response => {
          if (response.data.code === 200) {
            commit("SET_PRIMARY_SUCCESS", response.data.code);

            resolve({ status: true });
          } else {
            commit("SET_PRIMARY_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return setPrimary;
  },

  GetListNotification: ({ commit }, payload) => {

    let params = "?page="
    if (payload.page != "" && payload.page != undefined) {
      params += (payload.page ? encodeURIComponent((String(payload.page).replace('%', '\\%')).trim()) : "")
    }
    params += "&limit="
    if (payload.limit != "" && payload.limit != undefined) {
      params += (payload.limit ? encodeURIComponent((String(payload.limit).replace('%', '\\%')).trim()) : "")
    }

    const getListNotification = new Promise((resolve, reject) => {
      APICustomer.get(url.wsc.customer.notification_getlist + params)
        .then((response) => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.data);
            resolve({ status: true, data: response.data.data });
          } else {
            resolve({ status: false });
          }
        })
        .catch((e) => {
          reject(e);
        });
    });

    return getListNotification;
  },

  GetStatSubsNotif: ({ commit }, payload) => {
    const getStatSubsNotif = new Promise((resolve, reject) => {
      APICustomer.post(url.wsc.customer.get_stat_sub_notif, payload)
        .then(response => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.data);

            resolve({ status: true, data: response.data.data });
          } else {
            commit("CREATE_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return getStatSubsNotif;
  },

  UpdateSubsNotif: ({ commit }, payload) => {
    const dataPayload = {
      ...payload,
      pic: "2#" + version + "#" + store.state.auth.userData.customerUserID
    }
    const getStatSubsNotif = new Promise((resolve, reject) => {
      APICustomer.post(url.wsc.customer.get_stat_sub_notif, dataPayload)
        .then(response => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.data);

            resolve({ status: true, data: response.data.status });
          } else {
            commit("CREATE_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return getStatSubsNotif;
  },

  listDropdownMembership: ((_, payload) => {

    let params = "?dropdown_id="
    if (payload.dropdown_id != "" && payload.dropdown_id != undefined) {
      params += (payload.dropdown_id ? encodeURIComponent((String(payload.dropdown_id).replace('%', '\\%')).trim()) : "")
    }
    params += "&partner_id="
    if (payload.partner_id != "" && payload.partner_id != undefined) {
      params += (payload.partner_id ? encodeURIComponent((String(payload.partner_id).replace('%', '\\%')).trim()) : "")
    }
    params += "&city_name="
    if (payload.city_name != "" && payload.city_name != undefined) {
      params += (payload.city_name ? encodeURIComponent((String(payload.city_name).replace('%', '\\%')).trim()) : "")
    }
    params += "&school_grade="
    if (payload.school_grade != "" && payload.school_grade != undefined) {
      params += (payload.school_grade ? encodeURIComponent((String(payload.school_grade).replace('%', '\\%')).trim()) : "")
    }
    params += "&new_logic=" + encodeURIComponent((String(true).replace('%', '\\%')).trim())

    const getListOriginOfSchool = new Promise((resolve, reject) => {
      APICustomer.get(url.wsc.customer.list_dw_membership + params)
        .then(response => {
          if (response.status == 200) {
            resolve({ status: true, data: response.data.data });
          } else {
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return getListOriginOfSchool;
  })
};
