export default {
  CREATE_SUCCESS(state, val) {
    state.isResponse.code = val;
  },
  DELETE_CART_SUCCESS(state, val) {
    state.isResponse.code = val;
  },
  CREATE_FAILED(state, val) {
    state.isMsg.message = val;
  },
  DELETE_CART_FAILED(state, val) {
    state.isMsg.message = val;
  },
  GETQTYCART_SUCCESS(state, val) {
    state.countCart = val;
  },
  GETQTYCART_FAILED(state) {
    state.countCart = 0;
  },
  SET_CART(state, val) {
    state.cart = val;
    // state.cart = state.cart.map((el) => {
    //   var o = Object.assign({}, el);
    //   o.selected = true;
    //   return o;
    // });
  },
  GETQTYTICKET_SUCCESS(state, val) {
    state.countTicket = val;
  },
  GETQTYTICKET_FAILED(state) {
    state.countTicket = 0;
  },
  GET_AVAILABILITY_CAMPAIGN_OTOMATIS(state, val) {
    state.campaign_otomatis = val.data;
  },
  CHANGE_SELECTED(state, { index, val }) {
    state.cart[index].selected = val;
  },
  SELECT_ALL(state, val) {
    let countData = 0
    state.cart = state.cart.map((el) => {
      var o = Object.assign({}, el);
      o.selected = val;
      if (val) {
        countData += o.itemQty
      }
      return o;
    });
    state.countCart = countData;
  },
  DELETE_SELECTED(state, val) {
    state.cart = state.cart.map((el) => {
      var o = Object.assign({}, el);
      o.selected = val;
      return o;
    });
  },
  GET_STATUS_PAYMENT(state, val) {
    state.isResponse.code = val;
  },
  GETQTYTICKETBUS_SUCCESS(state, val) {
    state.countTicketBus = val;
  },
  SET_CURRENCY(state, val) {
    state.currency = val
  },
  SET_DISCOUNT(state, val) {
    state.discount = val
  },
};
