import APIDiscount from "@/utils/apiDiscount";
import { url } from '@/configs/apiUrl'

export default {
    getListDiscount: ({ commit }, payload) => {
        const getListDiscount = new Promise((resolve, reject) => {
            APIDiscount.post(url.wsc.discount.discount_redeem_list, payload).then(response => {
                if (response.data.code == 200) {
                    commit("CREATE_SUCCESS", response.data.code);
                    commit("GET_LIST_DISCOUNT", response.data.data);
                    resolve(response.data.data);
                } else {
                    commit("CREATE_FAILED", response.data.message);
                    resolve({ status: false });
                }
            }).catch(e => {
                reject(e);
            });
        });
        return getListDiscount;
    },
    validation: ({ commit }, payload) => {
        const discountAmount = new Promise((resolve, reject) => {
            APIDiscount.post(url.wsc.discount.discount_redeem_validation, payload).then(response => {
                if (response.data.code == 200) {
                    commit("CREATE_SUCCESS", response.data.code)
                    resolve({ status: true, data: response.data.data })
                } else {
                    commit("CREATE_FAILED", response.data.message)
                    resolve({ status: false })
                }
            }).catch(e => {
                reject(e)
            })
        })
        return discountAmount
    }
}