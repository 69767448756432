import { Number } from "core-js";

const state = function () {
  return {
    isResponse: Number,
    getFlateFare: {},
    countCart: Number,
    countTicket: Number,
    countTicketBus: 0,
    cart: [],
    cartSelected: [],
    campaign_otomatis: {},
    currency: {},
    discount: {
      data: {
        discount_id: ""
      },
      name: "",
      type: "",
      value: 0.0,
    },
    // userUpdatePassword: {},
    // ListPaymentMethod: [],
    // getPaymentMethod: {},
    // successPayment:{},
    isMsg: String
  };
};

export default state;
