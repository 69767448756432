import { Number } from "core-js";

const state = function() {
  return {
    isResponse: Number,
    getFlateFare:{},
    result:Number,
    isMsg: String
  };
};

export default state;
