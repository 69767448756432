import APIPurchaseTicket from "@/utils/apiPurchaseTicket";
import store from "@/store/index";
import { version } from '@/../package.json';
import { url } from '@/configs/apiUrl'

export default {
  getCurrencyConversionRate: ({ commit }, payload) => {
    const exampleCurrencies = [
      {
        name: 'USD - US Dollar',
        code: "USD",
        conversionRates: {
          USD: 1,
          VND: 0.00004,
          IDR: 0.000064,
        }
      },
      {
        name: 'VND - Vietnamese Dong',
        code: "VND",
        conversionRates: {
          VND: 1,
          USD: 24845,
          IDR: 1.6,
        }
      },
      {
        name: 'IDR - Indonesian Rupiah',
        code: "IDR",
        conversionRates: {
          IDR: 1,
          USD: 15540,
          VND: 0.63,
        }
      },
    ]
    const getCurrencies = new Promise((resolve, reject) => {
      /*
        example api call for future development of currency in fare and ticket management,
        call this action for get list of currencies and the conversion rates.
      */
      // APIPurchaseTicket.post("/api/v1/currencies/" + payload.currencyCode)
      //   .then((response) => {
      //     if (response.data.code == 200) {
      //       commit("SET_CURRENCY", response.data.data);
      //       resolve(response.data.data);
      //     }
      //   })
      //   .catch((e) => {
      //     reject(e);
      //   });

      const result = exampleCurrencies.find(currency => currency.code == payload.currencyCode)
      if (result) {
        commit("SET_CURRENCY", result);
        resolve(result)
      } else {
        reject(false)
      }
    });
    return getCurrencies;
  },
  getFlateFare: ({ commit }, payload) => {
    const getFlateFare = new Promise((resolve, reject) => {
      APIPurchaseTicket.get(
        url.wsc.purchase_ticket.flatfare_list+"?page=" +
        payload.page +
        "&limit=" +
        payload.limit
      )
        .then((response) => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.data);
            resolve(response.data.data);
          } else {
            resolve({ status: false });
          }
        })
        .catch((e) => {
          reject(e);
        });
    });

    return getFlateFare;
  },

  getMultiTrips: ({ commit }, payload) => {
    const getMultiTrips = new Promise((resolve, reject) => {
      APIPurchaseTicket.get(
        url.wsc.purchase_ticket.multidays_list+"?page=" +
        payload.page +
        "&limit=" +
        payload.limit
      )
        .then((response) => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.data);
            resolve(response.data.data);
          } else {
            resolve({ status: false });
          }
        })
        .catch((e) => {
          reject(e);
        });
    });

    return getMultiTrips;
  },
  getStudentFare: ({ commit }, payload) => {
    const getStudentFare = new Promise((resolve, reject) => {
      APIPurchaseTicket.get(
        url.wsc.purchase_ticket.studentfare+ "?page=" +
        payload.page +
        "&limit=" +
        payload.limit
      )
        .then((response) => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.data);
            resolve(response.data.data);
          } else {
            resolve({ status: false });
          }
        })
        .catch((e) => {
          reject(e);
        });
    });

    return getStudentFare;
  },
  // getStudentFare: () => {
  //   const getStudentFare = new Promise((resolve, reject) => {
  //     APIPurchaseTicket.get("/api/v1/cart/studentfare")
  //       .then(response => {
  //         if (response.data.code == 200) {
  //           resolve(response.data.data);
  //         } else {
  //           resolve({ status: false });
  //         }
  //       })
  //       .catch(e => {
  //         reject(e);
  //       });
  //   });

  //   return getStudentFare;
  // },

  checkAvailableCampaignOtomatis: ({ commit }) => {
    commit("GET_AVAILABILITY_CAMPAIGN_OTOMATIS", {});
    const getCampaign = new Promise((resolve, reject) => {
      APIPurchaseTicket.get(url.wsc.purchase_ticket.check_available_cart_otomatis)
        .then((response) => {
          if (response.data.code == 200) {
            commit("GET_AVAILABILITY_CAMPAIGN_OTOMATIS", response.data);
            resolve(response.data.data);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });

    return getCampaign;
  },
  getCart: ({ commit }, payload) => {
    const getCart = new Promise((resolve, reject) => {
      APIPurchaseTicket.post(url.wsc.purchase_ticket.show_cart, payload)
        .then((response) => {
          if (response.data.code == 200) {
            commit("SET_CART", response.data.data.data);
            resolve(response.data.data);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });

    return getCart;
  },
  addtocart: ({ commit }, payload) => {
    const dataPayload = {
      ...payload,
      pic: "2#" + version + "#" + store.state.auth.userData.customerUserID
    }
    const addtocart = new Promise((resolve, reject) => {
      APIPurchaseTicket.post(url.wsc.purchase_ticket.add_cart, dataPayload)
        .then((response) => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.data);

            resolve({ status: true });
          } else {
            commit("CREATE_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch((e) => {
          reject(e);
        });
    });

    return addtocart;
  },
  selectcart: (_, payload) => {
    const dataPayload = {
      ...payload,
      pic: "2#" + version + "#" + store.state.auth.userData.customerUserID
    }
    const addtocart = new Promise((resolve, reject) => {
      APIPurchaseTicket.post(url.wsc.purchase_ticket.select_cart, dataPayload)
        .then((response) => {
          if (response.data.code == 200) {
            resolve({ status: true, data: response.data.data.selected });
          } else {
            resolve({ status: false, data: false });
          }
        })
        .catch((e) => {
          reject(e);
        });
    });

    return addtocart;
  },
  selectallcart: (_, payload) => {
    const dataPayload = {
      ...payload,
      pic: "2#" + version + "#" + store.state.auth.userData.customerUserID
    }
    const selectallcart = new Promise((resolve, reject) => {
      APIPurchaseTicket.post(url.wsc.purchase_ticket.select_all_cart, dataPayload)
        .then((response) => {
          if (response.data.code == 200) {
            resolve({ status: true });
          } else {
            resolve({ status: false });
          }
        })
        .catch((e) => {
          reject(e);
        });
    })

    return selectallcart
  },
  delItem: ({ commit }, payload) => {
    const dataPayload = {
      ...payload,
      pic: "2#" + version + "#" + store.state.auth.userData.customerUserID
    }
    const addtocart = new Promise((resolve, reject) => {
      APIPurchaseTicket.post(url.wsc.purchase_ticket.delete_cart, dataPayload)
        .then((response) => {
          if (response.data.code == 200) {
            commit("DELETE_CART_SUCCESS", response.data.data);

            resolve({ status: true });
          } else {
            commit("DELETE_CART_FAILED", response.data.data);

            resolve({ status: false });
          }
        })
        .catch((e) => {
          reject(e);
        });
    });

    return addtocart;
  },
  updateCart: ({ commit }, payload) => {
    const getCart = new Promise((resolve, reject) => {
      APIPurchaseTicket.post(url.wsc.purchase_ticket.update_qty, payload)
        .then((response) => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.data);
            resolve(response.data.data);
          } else {
            commit("CREATE_FAILED", response.data.message);
            resolve({ status: false });
          }
        })
        .catch((e) => {
          reject(e);
        });
    });

    return getCart;
  },

  checkout: ({ commit }, payload) => {
    const dataPayload = {
      ...payload,
      pic: "2#" + version + "#" + store.state.auth.userData.customerUserID
    }
    const getCart = new Promise((resolve, reject) => {
      APIPurchaseTicket.post(
        url.wsc.purchase_ticket.payment_create,
        dataPayload
      ).then((response) => {
        if (response.data.code == 400 && response.data.message_code == 2020) {
          resolve({
            status: false,
            message: response.data.message,
          });
        } else if (response.data.code == 200 && response.data.message_code != 2020) {

          commit("CREATE_SUCCESS", response.data.data);
          resolve({
            status: true,
            url: response.data.data.data.redirect_url,
            payment_token: response.data.data.data.payment_token
          });
          if (
            payload.inv_no_old != "" &&
            payload.inv_no_old != undefined
          ) {
            var payloadUpdateStatus = {
              inv_no: "" + payload.inv_no_old + "",
            };
            APIPurchaseTicket.put(
              url.wsc.purchase_ticket.payment_update,
              payloadUpdateStatus
            ).then((response) => {
              console.log(response.message);
            });
          }
        } else {
          resolve({ status: false });
          commit("CREATE_FAILED", response.data.message);
        }
      }).catch((e) => {
        resolve({ status: false });
        reject(e);
      });
    });

    return getCart;
  },
  getQtyCart: ({ commit }, payload) => {
    const getQtyCart = new Promise((resolve, reject) => {
      APIPurchaseTicket.post(url.wsc.purchase_ticket.qty_cart, payload)
        .then((response) => {
          if (response.data.code == 200) {
            commit("GETQTYCART_SUCCESS", response.data.data.data.qty_cart);
          } else {
            commit("GETQTYCART_SUCCESS", 0);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });

    return getQtyCart;
  },
  getQtyTicket: ({ commit }, payload) => {
    const getQtyTicket = new Promise((resolve, reject) => {
      APIPurchaseTicket.post(url.wsc.purchase_ticket.qty_my_ticket, payload)
        .then((response) => {
          if (response.data.code == 200) {
            if (payload.bus_qr == undefined) {
              commit("GETQTYTICKET_SUCCESS", response.data.data.qty_myticket);
            } else {
              commit("GETQTYTICKETBUS_SUCCESS", response.data.data.qty_myticket);
            }
          } else {
            if (payload.bus_qr == undefined) {
              commit("GETQTYTICKET_SUCCESS", 0);
            } else {
              commit("GETQTYTICKETBUS_SUCCESS", 0);
            }
          }
        })
        .catch((e) => {
          if (payload.bus_qr == undefined) {
            commit("GETQTYTICKET_SUCCESS", 0);
          } else {
            commit("GETQTYTICKETBUS_SUCCESS", 0);
          }
          reject(e);
        });
    });

    return getQtyTicket;
  },
  getTransactionHistory: ({ commit }, payload) => {
    const getTransactionHistory = new Promise((resolve, reject) => {
      APIPurchaseTicket.get(
        url.wsc.purchase_ticket.purhasing_id_history + "?id="+
        store.state.auth.userData.customerID +
        "&page=" +
        payload.page +
        "&limit=" +
        payload.limit
      )
        .then((response) => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.data);
            resolve(response.data.data);
          } else {
            resolve({ status: false });
          }
        })
        .catch((e) => {
          reject(e);
        });
    });

    return getTransactionHistory;
  },
  getTransactionHistoryDetail: ({ commit }, payload) => {
    // https://cart-acasia.dev.ainosi.com/api/v1/purchasehistory/detail
    const getTransactionHistory = new Promise((resolve, reject) => {
      APIPurchaseTicket.post(url.wsc.purchase_ticket.purchasing_detail_history, payload)
        .then((response) => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.data);
            resolve(response.data.data);
          } else {
            resolve({ status: false });
          }
        })
        .catch((e) => {
          reject(e);
        });
    });

    return getTransactionHistory;
  },

  getStatusByPaymentToken: ({ commit }, payload) => {

    const getStatusByPaymentToken = new Promise((resolve, reject) => {
      APIPurchaseTicket.get(url.wsc.purchase_ticket.payment_token_status + payload)
        .then((response) => {
          if (response.data.code == 200) {
            commit("GET_STATUS_PAYMENT", response.data.data);
            resolve(response.data.data);
          } else {
            resolve({ status: false });
          }
        })
        .catch((e) => {
          reject(e);
        });
    });

    return getStatusByPaymentToken;
  },

  getStatusByOrderID: ({ commit }, payload) => {

    const getStatusByOrderID = new Promise((resolve, reject) => {
      APIPurchaseTicket.get(url.wsc.purchase_ticket.status_by_order_id + payload)
        .then((response) => {
          if (response.data.code == 200) {
            commit("GET_STATUS_PAYMENT", response.data.data);
            resolve(response.data.data);
          } else {
            resolve({ status: false });
          }
        })
        .catch((e) => {
          reject(e);
        });
    });

    return getStatusByOrderID;
  },
  getStatusByInvoice: ({ commit }, payload) => {

    const getStatusByInvoice = new Promise((resolve, reject) => {
      APIPurchaseTicket.get(url.wsc.purchase_ticket.get_status_by_invoice + payload)
        .then((response) => {
          if (response.data.code == 200) {
            commit("GET_STATUS_PAYMENT", response.data.data);
            resolve(response.data.data);
          } else {
            resolve({ status: false });
          }
        })
        .catch((e) => {
          reject(e);
        });
    });

    return getStatusByInvoice;
  },
  // -----------------------------------------------------------------------------------------------------------------------------------------------------
};
