const state = function() {
  return {
    toggleDrawer: false,
    routeInformation: "",
    checkout:"",
    minusQty:"",
    plusQty:"",
  };
};

export default state;
