export default {
    CREATE_SUCCESS(state, val) {
        state.isResponse.code = val;
    },
    CREATE_FAILED(state, val) {
        state.isMsg.message = val;
    },

    GET_LIST_DISCOUNT(state,val) {
        state.getListDiscount = val
    },
    GET_LIST_DISCOUNT_CODE(state,val) {
        state.getListDiscountCode = val
    },
}