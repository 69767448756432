import Cookies from "js-cookie";
// import { isEmpty } from "lodash";
// import state from "./moduleAuthState";

export default {
  getIsAuthentication: state => {
    return state.isAuthenticated;
  },
  getAccessToken: () => {
    return Cookies.get("access_token");
  },
  getRefreshToken: () => {
    return Cookies.get("refresh_token");
  },
  getUserData: state => {
    return state.userData;
  },
  isAllowedStudent: state => {
    if (state) {
      if (state.user_role == 'PASSENGER-STUDENT') {
        return true;
      } else {
        return false;
      }
    } else {
      return false
    }
  },
  isPremium: state => {
    if (state) {
      if (state.user_role == 'PASSENGER-PARRENTS' || state.user_role == 'PASSENGER-PREMIUM') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  getUserMerchantData: state => {
    return state.userMerchantData;
  },
  getParentalAccess: state => {
    return state.parentalAccess;
  },
  getInitialPath: state => {
    return state.initialPath;
  },
  getUserRole: state => {
    return state.user_role;
  }
};
