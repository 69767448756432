import i18n from '../i18n/i18n.js';
import {version} from '@/../package.json';
import moment from 'moment';

// src/helpers/utilities.js
// these functions can be used without importing
export default {
    // prevent type < > " ' & 
    typeSearchBlockHandler(event) {
        if( event.key === "<" || event.keyCode === 60||
          event.key === ">" || event.keyCode === 62||
          event.key === '"' || event.keyCode === 34||
          event.key === "'" || event.keyCode === 39||
          event.key === "&" || event.keyCode === 38 ){
          event.preventDefault();
          return false;
        }
        return true;
    },
    // prevent type "e", minus, and plus on field number 
    typeNumberOnPressInputHandler(event) {
        if(event.code === "KeyE" || event.key === "e" || event.keyCode === 69 || event.keyCode === 101 ||
        event.code === "Minus" || event.key === "-" || event.keyCode === 45||
        event.code === "Equal" || event.key === "+" || event.keyCode === 43){
          event.preventDefault();
          return false;
        }
        return true;
      },

    // prevent type "e", minus, plus, and point on field number
    typeIntegerOnPressInputHandler(event) {
      if(event.code === "KeyE" || event.key === "e" || event.keyCode === 69 || event.keyCode === 101 ||
      event.code === "Minus" || event.key === "-" || event.keyCode === 45||
      event.code === "Equal" || event.key === "+" || event.keyCode === 43||
      event.key === "."){
        event.preventDefault();
        return false;
      }
      return true;
    },
    defaultDropdownList(){
      var object = {
        id:0,
        name:"All",
        label:i18n._vm.messages[i18n._vm.locale].All
      }
      return object
    },
    pic(){
      return "1#" + version + "#" + JSON.parse(localStorage.getItem("userInfo")).user.user_id;
    },
    formatDateTime(datestr){
      return moment(datestr).format("DD/MM/YYYY (HH:mm:ss)")
    },
    formatDate(datestr){
      return moment(datestr).format("DD/MM/YYYY")
    },
    formatTime(datestr){
      return moment(datestr).format("HH:mm:ss")
    },
    formatDateToTimestamp0000(datestr){
      return moment(datestr,"DD/MM/YYYY").format("YYYY-MM-DD") + "T00:00:00.000Z"
    },
    formatDateToTimestamp2359(datestr){
      return moment(datestr,"DD/MM/YYYY").format("YYYY-MM-DD") + "T23:59:59.999Z"
    },
    uuidv4() {
      return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    },
    pad(d) {
      return d < 10 ? "0" + d.toString() : d.toString();
  }
};
