import APINotification from "@/utils/apiOTP"
import { url } from '@/configs/apiUrl'

export default {
    checkFcmStatus: ({ commit }, payload) => {
        const detailFCMStatus = new Promise((resolve, reject) => {
            APINotification.post(url.wsc.notification.fcm_notif, payload)
                .then(response => {
                    if (response.data.code == 200) {
                        commit("SET_RESPONSE", response.data);
                        resolve({ status: true, data: response.data });
                    } else {
                        resolve({ message: response.data.message });
                    }
                })
                .catch(e => {
                    reject(e);
                });
        });

        return detailFCMStatus;
    },
}