import state from "./moduleUiState.js";
import mutations from "./moduleUiMutations.js";
import actions from "./moduleUiActions.js";
import getters from "./moduleUiGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
