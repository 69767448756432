import Vue from 'vue'
import Vuex from 'vuex'
//Vuex Helper
import pathify from './pathify'
import createPersistedState from "vuex-persistedstate";

//Modular Vuex

import moduleAuth from './auth/moduleAuth.js'
import modulePassenger from './passenger/modulePassenger.js'
import moduleChildlinkage from './childlinkage/moduleChildlinkage.js'
import moduleCdn from './cdn/moduleCdn.js'
import moduleUi from './ui/moduleUi.js'
import modulePurchaseTicket from './purchaseTicket/modulePurchaseTicket';
import moduleMyTicket from './myTicket/moduleMyTicket';
import moduleMPMValidate from './mpmValidate/moduleMPMValidate';
import moduleMyTrip from './myTrip/moduleMyTrip';
import moduleMenuManagement from './menuManagement/moduleMenuManagement.js'
import modulePayment from './payment/modulePayment.js'

import moduleCustomer from './customer/moduleCustomer.js'
import moduleDiscount from './discount/moduleDiscount';
import moduleCPMValidate from './cpmValidate/moduleCPMValidate';
import moduleNotification from './notification/moduleNotification';
import moduleOrchestrator from './orchestrator/moduleOrchestrator';

Vue.use(Vuex)
pathify.debug();

const persistData = true;
const storePlugins = persistData
  ? [createPersistedState(), pathify.plugin]
  : [pathify.plugin];
  
export default new Vuex.Store({
  plugins: storePlugins,
  modules: {
    auth: moduleAuth,
    passenger: modulePassenger,
    customer: moduleCustomer,
    childlinkage: moduleChildlinkage,
    cdn:moduleCdn,
    purchaseticket: modulePurchaseTicket,
    myticket: moduleMyTicket,
    mytrip: moduleMyTrip,
    mpm: moduleMPMValidate,
    menuManagement: moduleMenuManagement,
    payment: modulePayment,
    moduleUi,
    discount: moduleDiscount,
    cpm: moduleCPMValidate,
    notification: moduleNotification,
    orchestrator: moduleOrchestrator
  },
  strict: process.env.NODE_ENV !== 'production'
})
