import APIChildlinkage from "@/utils/apiChildlinkage";
import store from "@/store/index";

export default {
  create: ({ commit }, payload) => {
    

    const createStudent= new Promise((resolve, reject) => {
      APIChildlinkage.post("/api/v1/childlinkage/reg", payload)
        .then(response => {
          if (response.data.code == 200) {
            console.log("log response ", response.data)
            commit("CREATE_SUCCESS", response.data.code);

            resolve({ status: true });
          } else {
            commit("CREATE_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return createStudent;
  },

  confirm_student: ({ commit }, payload) => {
    

    const createStudent= new Promise((resolve, reject) => {
      APIChildlinkage.post("/api/v1/childlinkage/confirm", payload)
        .then(response => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.code);

            resolve({ status: true });
          } else {
            commit("CREATE_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return createStudent;
  },
  delete_child: ({ commit }, payload) => {
    

    const createStudent= new Promise((resolve, reject) => {
      APIChildlinkage.post("/api/v1/childlinkage/delete", payload)
        .then(response => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.code);

            resolve({ status: true });
          } else {
            commit("CREATE_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return createStudent;
  },

  getListSchool: ({ commit } ) => {
    

    const ListSchool= new Promise((resolve, reject) => {
      APIChildlinkage.get("/api/v1/childlinkage/list-school")
        .then(response => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.code);
            let dataArray = [] 
            if (response.data.data == null) {
              dataArray.data = [] 
              dataArray.data.length = 0
            } else {
              dataArray = response.data 
            } 
            resolve(dataArray);
          } else {
            commit("CREATE_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return ListSchool;
  },

  getStudent: ({ commit } ) => {
    

    const getStudent= new Promise((resolve, reject) => {
      APIChildlinkage.get("/api/v1/childlinkage/list")
        .then(response => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.code);
            let dataArray = [] 
            if (response.data.data == null) {
              dataArray.data = [] 
              dataArray.data.length = 0
            } else {
              dataArray = response.data 
            } 
            resolve(dataArray);
          } else {
            commit("CREATE_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return getStudent;
  },

  getStudentRequest: ({ commit } ) => {
    

    const getStudentRequest= new Promise((resolve, reject) => {
      APIChildlinkage.get("/api/v1/childlinkage/req-list")
        .then(response => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.code);

            let dataArray = [] 
            if (response.data.data === null) {
              dataArray.data = [] 
              dataArray.data.length = 0
            } else {
              dataArray = response.data 
            } 
            resolve( dataArray );
          } else {
            commit("CREATE_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return getStudentRequest;
  },

  getDetailStudent: ({ commit },payload ) => {
    

    const getStudent= new Promise((resolve, reject) => {
      APIChildlinkage.post("/api/v1/childlinkage/detail", payload)
        .then(response => {
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.code);

            resolve( response.data.data );
          } else {
            commit("CREATE_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });


    return getStudent;
  },

  sofPaymentDelete: ({ commit }, payload) => {
    

    const deletePaymentMethod = new Promise((resolve, reject) => {
      APIChildlinkage.put("/api/v1/passenger-sof/delete", payload)
        .then(response => {
          // console.log("log response ", response)
          if (response.data.code == 200) {
            commit("DELETE_PAYMENT_METHOD_SUCCESS", response.data.code);

            resolve({ status: true });
          } else {
            commit("DELETE_PAYMENT_METHOD_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return deletePaymentMethod;
  },

  editPaymentMethod: ({ commit }, payload) => {
    

    const editPaymentMethod = new Promise((resolve, reject) => {
      APIChildlinkage.put("/api/v1/passenger-sof/edit", payload)
        .then(response => {
          if (response.data.code === 200) {
            commit("EDIT_PAYMENT_METHOD_SUCCESS", response.data.code);

            resolve({ status: true });
          } else {
            commit("EDIT_PAYMENT_METHOD_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return editPaymentMethod;
  },
  getPaymentByCodeNumber: ({ commit }, payload) => {
    

    const getPaymentByCodeNumber = new Promise((resolve, reject) => {
      APIChildlinkage.post("/api/v1/passenger-sof/get-payment", payload)
        .then(response => {
          if (response.status == 200) {
            commit("GET_OBJECT_PAYMENT_METHOD_SUCCESS", response.data.data);

            resolve(response.data);
          } else {
            commit("GET_OBJECT_PAYMENT_METHOD_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return getPaymentByCodeNumber;
  },
  getDataUser: () => {
       const getData= {
          detail: `${store.getters["auth/getUserData"]}`
        }
        console.log("dat a",getData)
      return  getData;
  },
  
};
