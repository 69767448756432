import APICdn from "@/utils/apiCdn";
import { url } from '@/configs/apiUrl'

export default {
  uploadPhotoIdentity: ({ commit }, payload) => {
    let formData = new FormData();
    formData.append('file', payload.file, payload.filename);

    const uploadToPhoto= new Promise((resolve, reject) => {
      APICdn.post(url.wsc.cdn.file_upload, formData)
        .then(response => {
          if (response.data.code == 200) {
            commit("CREATE_IDENTITY_CARD", response.data.data.name);

            resolve({ status: true, photo: response.data.data.name });
          } else {
            commit("CREATE_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });
    return uploadToPhoto;
  },

  uploadPhotoSelfieID: ({ commit }, payload) => {
    let formData = new FormData();
    formData.append('file', payload.file, payload.filename);

    const uploadPhotoSelfie = new Promise((resolve, reject) => {
      APICdn.post(url.wsc.cdn.file_upload, formData)
        .then(response => {
          console.log("log response ", response.data)
          if (response.data.code == 200) {
            commit("CREATE_SELFIE_CARD", response.data.data.name);

            resolve({ status: true, photo: response.data.data.name});
          } else {
            commit("CREATE_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });
    return uploadPhotoSelfie;

  },

  uploadPhotoSelfie: ({ commit }, payload) => {
    let formData = new FormData();
    formData.append('file', payload.file, payload.filename);

    const uploadPhotoSelfie = new Promise((resolve, reject) => {
      APICdn.post(url.wsc.cdn.file_upload, formData)
        .then(response => {
          console.log("log response ", response.data)
          if (response.data.code == 200) {
            commit("CREATE_SELFIE", response.data.data.name);

            resolve({ status: true, photo: response.data.data.name});
          } else {
            commit("CREATE_FAILED", response.data.message);

            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });
    return uploadPhotoSelfie;

  },
};
