import APIMyTrip from "@/utils/apiMyTrip";
import { url } from '@/configs/apiUrl'
// import store from "@/store";

export default {
  triphistory: ({ commit }, payload) => {
    

    const flatefare = new Promise((resolve, reject) => {
      APIMyTrip.post(url.wsc.my_trip.list+"?page="+payload.page +"&limit="+payload.limit, payload.payload)
        .then(response => {
          // console.log(response.data.code, response.data.data.data)
          if (response.data.code == 200) {
            commit("CREATE_SUCCESS", response.data.data);
            resolve(response.data.data);
          } else {
            commit("CREATE_FAILED", response.data.message);
            resolve({ status: false });
          }
        })
        .catch(e => {
          reject(e);
        });
    });

    return flatefare;
  },

// -----------------------------------------------------------------------------------------------------------------------------------------------------

};
