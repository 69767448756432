import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
  // breakpoint: {
  //   thresholds: {
  //     xs: 340,
  //     sm: 540,
  //     md: 800,
  //     lg: 1280,
  //   },
  //   scrollBarWidth: 24,
  // },
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        // primary: '#A02592',
        primary:'#4BB14E',
        secondary: '#804BB14E',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        danger:'#F25065',
        white:'#FFFFFF',
      },
    },
  },
  icons: {
    iconfont: 'fa',
  },
});
